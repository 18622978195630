import React from "react";
import { Row, Col, ListGroup, Tab } from "react-bootstrap";
import InsideBlogs from "../InsideBlogs/InsideBlogs";
import blogs from "../../json/Blogs/blogs.js";

function InsideEzok() {
  return (
    <>
      <Tab.Container id="list-group-tabs-example" defaultActiveKey="#link1">
        <Row className="py-5">
          <Col md={3}>
            <div style={{ backgroundColor: "#000000", padding: "30px 40px" }}>
              <h2 style={{ color: "white" }}>Blogs</h2>
              <ListGroup variant="flush">
                {/* <ListGroup.Item action href="#link1" variant="secondary">
                  News
                </ListGroup.Item> */}
                {/* <ListGroup.Item action href="#link2" variant="secondary">
                  People & Places
                </ListGroup.Item>
                <ListGroup.Item action href="#link3" variant="secondary">
                  Lifestyle
                </ListGroup.Item>
                <ListGroup.Item action href="#link4" variant="secondary">
                  Before & after
                </ListGroup.Item>
                <ListGroup.Item action href="#link5" variant="secondary">
                  Fashion
                </ListGroup.Item> */}
              </ListGroup>
            </div>
          </Col>
          <Col md={1}></Col>
          <Col md={8}>
            <Tab.Content>
              <Tab.Pane eventKey="#link1">
                <InsideBlogs
                  blogData={blogs.filter((x) => x.category === "news")}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="#link2">
                <InsideBlogs
                  blogData={blogs.filter((x) => x.category === "people")}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="#link3">
                <InsideBlogs
                  blogData={blogs.filter((x) => x.category === "lifestyle")}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="#link4">
                <InsideBlogs
                  blogData={blogs.filter((x) => x.category === "before")}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="#link5">
                <InsideBlogs
                  blogData={blogs.filter((x) => x.category === "fashion")}
                />
              </Tab.Pane>
            </Tab.Content>
          </Col>
        </Row>
      </Tab.Container>
    </>
  );
}

export default InsideEzok;
// import React, { useContext, useEffect } from "react";
// import { ShopContext } from "../../context/shopContext";
// import { Row, Col, ListGroup, Tab } from "react-bootstrap";
// import BlogComponent from "../BlogComponent/BlogComponent";
// import BlogLoader from "../Loader/BlogLoader";
// // import blogs from "../../json/Blogs/blogs.js";

// function InsideEzok() {
//   const {
//     blogs,
//     fetchBlogs,
//     singleBlog,
//     fetchSingleBlog,
//     articles,
//     fetchArticles,
//   } = useContext(ShopContext);

//   const handleBlog = (id) => {
//     fetchArticles(id);
//   };

//   useEffect(() => {
//     fetchBlogs();
//   }, []);

//   useEffect(() => {
//     handleBlog(blogs && blogs.blogs && blogs.blogs[0].id);
//   }, [blogs]);

//   return (
//     <>
//       <Tab.Container
//         id="list-group-tabs-example"
//         // defaultActiveKey={() =>
//         //   handleBlog(blogs && blogs.blogs && blogs.blogs[0].id)
//         // }
//       >
//         <Row className="py-5 sideNav">
//           <Col md={3}>
//             <div style={{ backgroundColor: "#E2E3E5", padding: "30px 40px" }}>
//               <h2>Blogs</h2>
//               <ListGroup variant="flush">
//                 {blogs &&
//                   blogs.blogs &&
//                   blogs.blogs.map((blog) => (
//                     <ListGroup.Item
//                       action
//                       href={"#asd"}
//                       variant="secondary"
//                       onClick={() => handleBlog(blog.id)}
//                       key={blog.id}
//                     >
//                       {blog.title}
//                     </ListGroup.Item>
//                   ))}
//               </ListGroup>
//             </div>
//           </Col>
//           <Col md={1}></Col>
//           <Col md={8}>
//             <Tab.Content>
//               <Row>
//                 {articles && articles.articles ? (
//                   articles.articles.map((article) => (
//                     <Col md={6} key={article.id}>
//                       <Tab.Pane eventKey="#asd">
//                         <BlogComponent blogData={article} />
//                       </Tab.Pane>
//                     </Col>
//                   ))
//                 ) : (
//                   <BlogLoader />
//                 )}
//               </Row>
//             </Tab.Content>
//           </Col>
//         </Row>
//       </Tab.Container>
//     </>
//   );
// }

// export default InsideEzok;
