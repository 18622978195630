import React from "react";
import { Row, Col, Image, Container } from "react-bootstrap";
import Product from "../Product/Product";
import { Link } from "react-router-dom";
import "../ProductParent/ProductParent.css";
import Loader from "../Loader/Loader";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import "swiper/components/scrollbar/scrollbar.min.css";

import SwiperCore, {
  Mousewheel,
  Navigation,
  Pagination,
  Autoplay,
  Scrollbar,
} from "swiper/core";
import { ArrowRight } from "react-bootstrap-icons";

SwiperCore.use([Mousewheel, Pagination, Navigation, Autoplay, Scrollbar]);

function ProductParent({ products }) {
  const customstyle = {
    objectFit: "cover",
    maxWidth: "270px",
    maxHeight: "200px",
    verticalAlign: "middle",
  };

  return (
    <>
      <Container>
        <Row className="mt-5 adjustrowpp">
          <Col md={6}>
            <Row className="newArow">
              <Col className="text-center">
                <div className="text-center pb-4 adjustdivpp">
                  <h5 className="Custom-headings-home-na">Ezok New Arrivals</h5>
                </div>
                {/* <Image
                  src="/images/dividers/newarrivals.jpg"
                  fluid
                  className="headingImage"
                /> */}
              </Col>
            </Row>
            {products.length === 0 ? (
              <Loader />
            ) : (
              <Row className="newArow">
                <Col className="nopaddingonparent">
                  <Swiper
                    direction={"horizontal"}
                    spaceBetween={20}
                    className="mySwiper"
                    // pagination={{ clickable: true }}
                    autoplay={{ delay: "2000" }}
                    loop
                    slidesPerView={2.25}
                    navigation
                    scrollbar={{ draggable: true }}
                  >
                    {products.slice(10, 20).map((product) => {
                      return (
                        <SwiperSlide key={product.id}>
                          <Product product={product} styles={customstyle} />
                        </SwiperSlide>
                      );
                    })}
                  </Swiper>
                </Col>
              </Row>
            )}
            <Row className="text-center mpadAdjustE">
              <Col>
                <Link
                  to="/collections2/all"
                  style={{
                    fontFamily: "sans-serif",
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  Explore more&nbsp;
                  <ArrowRight />
                </Link>
              </Col>
            </Row>
          </Col>
          <Col md={6} className="paddingPT">
            <Image src="/images/banners/b3s2.jpg" fluid />
            {/* <div className="caption-box3">
              <div className="C-title3">
                <h1>asdasdsd</h1>
              </div>
            </div> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default ProductParent;
