import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import blogs from "../json/Blogs/blogs";

function BlogPage({ match }) {
  const blogId = match.params.id;
  const blog = blogs.filter((x) => x.id === blogId);

  return (
    <>
      <Container className="py-5 blogContainer">
        <h3>Blogs</h3>
        <Row className="py-2 w-75 m-auto widthAdjust mt-3">
          <Col>
            <h1 style={{ color: "#2a2a2ad1" }}>{blog[0].title}</h1>
            <p>{blog[0].subject}</p>
            <p className="datetext2">{blog[0].date}</p>
            <Image
              src={blog[0].image}
              style={{
                objectFit: "cover",
                objectPosition: "center",
                width: "100%",
                height: "600px",
              }}
              className="blogmimg"
            />
            <p className="pt-5">{blog[0].description}</p>
            {/* <p className="py-3">{blog[0].description}</p>
            <p className="pb-5">{blog[0].description}</p> */}
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default BlogPage;
// import React, { useContext, useEffect } from "react";
// import { ShopContext } from "../context/shopContext";
// import { Container, Row, Col, Image } from "react-bootstrap";
// import BlogLoader from "../components/Loader/BlogLoader";

// function BlogPage({ match }) {
//   const { singleArticle, fetchSingleArticle } = useContext(ShopContext);
//   const blogId = match.params.blogId;
//   const articleId = match.params.id;
//   // const blog = blogs.filter((x) => x.id === blogId);

//   useEffect(() => {
//     fetchSingleArticle(blogId, articleId);
//   }, [blogId, articleId]);

//   return (
//     <>
//       <Container className="py-5 blogContainer">
//         <h3>Blogs</h3>
//         {Object.entries(singleArticle).length === 0 ? (
//           <BlogLoader />
//         ) : (
//           <Row className="py-2 w-75 m-auto widthAdjust">
//             <Col>
//               <h1 style={{ color: "#2a2a2ad1" }}>
//                 {singleArticle.article && singleArticle.article.title}
//               </h1>
//               <p>{singleArticle.article && singleArticle.article.handle}</p>
//               <p className="datetext2">
//                 {singleArticle.article &&
//                   singleArticle.article.published_at.substring(0, 10)}
//               </p>
//               <Image
//                 src={
//                   singleArticle.article &&
//                   singleArticle.article.image &&
//                   singleArticle.article &&
//                   singleArticle.article.image.src
//                 }
//                 style={{
//                   objectFit: "cover",
//                   width: "100%",
//                   height: "600px",
//                   objectPosition: "center",
//                 }}
//               />
//               <p className="pt-5">
//                 {singleArticle.article && singleArticle.article.summary_html}
//               </p>
//             </Col>
//           </Row>
//         )}
//       </Container>
//     </>
//   );
// }

// export default BlogPage;
