import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import "../EzokAdvantage/EzokAdvantage.css";

function EzokAdvantage() {
  return (
    <>
      <Row
        className="advantageRow mt-3"
        style={{ width: "100%", margin: "auto" }}
      >
        <Col sm={12} md={5}>
          <Image
            src="images/advantage/a12.jpg"
            fluid
            style={{ height: "auto" }}
          ></Image>
        </Col>

        <Col xs={4} md={2} className="advantageCol">
          <div className="content1">
            <div className="content-overlay1"></div>
            <Image src="images/advantage/n4-2.jpg" fluid></Image>
            <div className="content-details1 nplr ">
              <h5 className="content-title1">Genuine Leather</h5>
            </div>
          </div>
        </Col>
        <Col xs={4} md={2} className="advantageCol">
          <div className="content1">
            <div className="content-overlay1"></div>
            <Image src="images/advantage/n5-2.jpg" fluid></Image>
            <div className="content-details1 nplr ">
              <h5 className="content-title1">Sustainability</h5>
            </div>
          </div>
        </Col>
        <Col xs={4} md={2} className="advantageCol">
          <div className="content1">
            <div className="content-overlay1"></div>
            <Image src="images/advantage/n6.jpg" fluid></Image>
            <div className="content-details1 nplr ">
              <h5 className="content-title1">Styled In Italy</h5>
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default EzokAdvantage;
