import { Pinterest, Facebook, Twitter, Instagram } from "@material-ui/icons";
import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Image,
  Form,
  FormControl,
  Button,
} from "react-bootstrap";
import { ArrowRight, Linkedin, Youtube } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import "../Footer2/Footer2.css";
import Message from "../Message/Message";

function Footer2() {
  const [isSubmit, setIsSubmit] = useState(false);

  return (
    <footer className="hidefooterm">
      {isSubmit && <Message varient="success">Subscribed Success ✅</Message>}
      <Container>
        <Row className="textcenterFooter padadjust">
          <Col md={2} className="centerFooter notcenter flexprop">
            <Image
              src="/images/logo/logo.png"
              fluid
              className="whitelogo"
            ></Image>
          </Col>
          <Col md={3} className="flexprop2">
            <h6>
              <Link
                to="/about"
                style={{
                  color: "white",
                  textDecoration: "none",
                  letterSpacing: "2px",
                  fontSize: "1.2rem",
                }}
              >
                ABOUT US
              </Link>
            </h6>
            <div
              style={{
                height: "0.5px",
                width: "30px",
                background: "#C8A44C",
                marginBottom: "10px",
              }}
            ></div>
            <h6
              style={{
                letterSpacing: "2px",
                fontSize: "1.2rem",
              }}
            >
              CUSTOMER SERVICE
            </h6>
            <div
              style={{
                height: "0.5px",
                width: "60px",
                background: "#C8A44C",
                marginBottom: "10px",
              }}
            ></div>
            <Link to="/faq" className="footerLink">
              FAQS
            </Link>

            <Link to="/ContactUs" className="footerLink">
              CONTACT US
            </Link>
          </Col>
          <Col md={3} className="flexprop2">
            <h6
              style={{
                letterSpacing: "2px",
                fontSize: "1.2rem",
              }}
            >
              LEGAL
            </h6>
            <div
              style={{
                height: "0.5px",
                width: "30px",
                background: "#C8A44C",
                marginBottom: "10px",
              }}
            ></div>
            <Link to="/termsandconditions" className="footerLink">
              TERMS AND CONDITIONS
            </Link>

            <Link to="/privacypolicy" className="footerLink">
              PRIVACY POLICY
            </Link>

            {/* <Link to="/deliverypolicy" className="footerLink">
              Delivery Policy
            </Link> */}

            <Link to="/returnpolicy" className="footerLink">
              RETURN POLICY
            </Link>
            <br />
          </Col>

          <Col md={4} className="textcenterFooter footermarginbottom dispnone">
            <div
              style={{
                border: "1px solid #3c3c3c",
                padding: "25px 20px",
              }}
            >
              <h6
                style={{
                  letterSpacing: "2px",
                  fontSize: "1.2rem",
                }}
              >
                SIGN UP FOR OUR NEWSLETTER
              </h6>
              <div
                style={{ height: "1px", width: "100px", background: "#C8A44C" }}
              ></div>
              <br></br>

              <Form
                onSubmit={() => setIsSubmit((prev) => !prev)}
                method="post"
                action="http://ezokshoes.com/email/index.php"
                className="d-flex mb-0"
              >
                <FormControl
                  type="text"
                  className="mr-2 input2 input22"
                  aria-label="Search"
                  name="email"
                  id="email"
                  placeholder="Enter your email address"
                ></FormControl>
                <Button
                  type="submit"
                  variant="outline-dark"
                  className="btn btn-outline-dark input2Button input22Button"
                  name="submit1"
                  id="submit1"
                >
                  <ArrowRight style={{ color: "white" }} />
                </Button>
              </Form>
              <Row>
                <Col xs={1}>
                  <Instagram style={{ width: "1.2rem" }} />
                </Col>
                <Col xs={1}>
                  <Facebook style={{ width: "1.2rem" }} />
                </Col>
                <Col xs={1}>
                  <Twitter style={{ width: "1.2rem" }} />
                </Col>
                <Col xs={1}>
                  <Pinterest style={{ width: "1.2rem" }} />
                </Col>
                {/* <Col xs={1}>
                  <Linkedin />
                </Col> */}
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer2;
