import React, { useState, useEffect, useContext } from "react";
import { ShopContext } from "../context/shopContext";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import Jumbotrons from "../components/Jumbotrons/Jumbotrons";
import Banner from "../components/Banner2/Banner";
import Product from "../components/Product/Product";
import Loader from "../components/Loader/Loader";

function CBestSellers() {
  const {
    fetchCollections,
    collections,
    products,
    fetchAllProducts,
    singleCollection,
    fetchSingleCollection,
  } = useContext(ShopContext);

  const moccasinCollection =
    collections.length && collections.filter((x) => x.title === "Best Seller");
  const collectionId = String(collections.length && moccasinCollection[0].id);

  useEffect(() => {
    fetchCollections();
    fetchAllProducts();
    fetchSingleCollection(collectionId);
  }, [collectionId]);

  const customstyle = {
    maxWidth: "100%",
    maxHeight: "100%",
  };

  return (
    <>
      <Banner
        text="BEST SELLER"
        url="https://cdn.shopify.com/s/files/1/0570/6370/2695/files/1._Banner-1920x1080.jpg?v=1627035089"
      />
      <Container className="mb-5">
        <Jumbotrons />
        <Row className="py-3 text-center">
          <Col>
            <h2 style={{ color: "#343434" }} className="Custom-headings">
              BEST SELLER
            </h2>
          </Col>
        </Row>
        <Row>
          {/* <Col md={3} className="filterDisplay">
            <Row>
              <Col>
                <p className="filter-texts">Color </p>
                <ListGroup horizontal className="rounded-0">
                  <ListGroup.Item
                    style={{ backgroundColor: "black", marginRight: "10px" }}
                  >
                    &nbsp;&nbsp;&nbsp;
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="rounded-0"
                    style={{ backgroundColor: "tan", marginRight: "10px" }}
                  >
                    &nbsp;&nbsp;&nbsp;
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="rounded-0"
                    style={{ backgroundColor: "brown", marginRight: "10px" }}
                  >
                    &nbsp;&nbsp;&nbsp;
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="rounded-0"
                    style={{ backgroundColor: "#4c0013", marginRight: "10px" }}
                  >
                    &nbsp;&nbsp;&nbsp;
                  </ListGroup.Item>
                  <ListGroup.Item
                    className="rounded-0"
                    style={{ backgroundColor: "#423636", marginRight: "10px" }}
                  >
                    &nbsp;&nbsp;&nbsp;
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p className="filter-texts">Size </p>
                <ListGroup horizontal className="rounded-0">
                  <ListGroup.Item
                    action
                    style={{ marginRight: "10px" }}
                    className="rounded-0 border-1"
                  >
                    40
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    className="rounded-0 border-1"
                    style={{ marginRight: "10px" }}
                  >
                    41
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    className="rounded-0 border-1"
                    style={{ marginRight: "10px" }}
                  >
                    42
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    className="rounded-0 border-1"
                    style={{ marginRight: "10px" }}
                  >
                    43
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    className="rounded-0 border-1"
                    style={{ marginRight: "10px" }}
                  >
                    44
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p className="filter-texts">Price </p>
                <ListGroup variant="flush" className="rounded-0">
                  <ListGroup.Item
                    action
                    style={{ marginRight: "10px" }}
                    className="rounded-0"
                  >
                    ₹1999 - ₹2999
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    className="rounded-0"
                    style={{ marginRight: "10px" }}
                  >
                    ₹2199 - ₹2999
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    className="rounded-0"
                    style={{ marginRight: "10px" }}
                  >
                    ₹2999 - ₹3999
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p className="filter-texts">Material </p>
                <ListGroup variant="flush" className="rounded-0">
                  <ListGroup.Item
                    action
                    style={{ marginRight: "10px" }}
                    className="rounded-0"
                  >
                    Leather
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p className="filter-texts">Sort</p>
                <ListGroup variant="flush" className="rounded-0">
                  <ListGroup.Item
                    action
                    style={{ marginRight: "10px" }}
                    className="rounded-0"
                  >
                    Alphabetically: A-Z
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    className="rounded-0"
                    style={{ marginRight: "10px" }}
                  >
                    Price: Low To High
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    className="rounded-0"
                    style={{ marginRight: "10px" }}
                  >
                    Date: Old To New
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p className="filter-texts">Lining</p>
                <ListGroup variant="flush" className="rounded-0">
                  <ListGroup.Item
                    action
                    style={{ marginRight: "10px" }}
                    className="rounded-0"
                  >
                    Leather
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
          </Col>
          <Col md={1}></Col> */}
          <Col md={12}>
            <Row xs={2} md={5}>
              {singleCollection.products ? (
                singleCollection.products.map((product) => (
                  <Col>
                    <Product product={product} styles={customstyle} />
                  </Col>
                ))
              ) : (
                <Loader />
              )}
            </Row>
          </Col>
        </Row>
      </Container>

      <Container className="mb-5">
        <Row className="py-3 text-center">
          <Col>
            <h2 style={{ color: "#343434" }} className="Custom-headings">
              COLLECTIONS
            </h2>
          </Col>
        </Row>

        <Row xs={2} sm={3} md={5}>
          {products.length === 0 ? (
            <Loader />
          ) : (
            products.map((product) => (
              <Col>
                <Product product={product} styles={customstyle} />
              </Col>
            ))
          )}
        </Row>
      </Container>
    </>
  );
}

export default CBestSellers;
