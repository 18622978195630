import React from "react";
import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

function MyAccountNav2() {
  return (
    <>
      <div style={{ backgroundColor: "#E2E3E5", padding: "30px 40px" }}>
        <h2>My Account</h2>
        <ListGroup variant="flush">
          <Link to="/userdashboard" style={{ textDecoration: "none" }}>
            <ListGroup.Item action variant="secondary border-0">
              My Profile
            </ListGroup.Item>
          </Link>
          <Link to="/userorders" style={{ textDecoration: "none" }}>
            <ListGroup.Item action variant="secondary border-0">
              My Orders
            </ListGroup.Item>
          </Link>
          <Link to="/logout" style={{ textDecoration: "none" }}>
            <ListGroup.Item action variant="secondary border-0">
              Logout
            </ListGroup.Item>
          </Link>
        </ListGroup>
      </div>
    </>
  );
}

export default MyAccountNav2;
