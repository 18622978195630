import React from "react";
import { Card } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import "../BlogComponent/BlogComponent.css";

function BlogComponent({ blog }) {
  return (
    <>
      <Card>
        <Card.Img variant="top" src={blog.image} />
        <Card.Text class="datetext">{blog.date}</Card.Text>
        <Card.Body style={{ padding: "15px 0px" }}>
          <Card.Title className="bebas titlecardsblogs">
            {blog.title}
          </Card.Title>
          <Card.Text>
            {blog.title && blog.description.substring(0, 100)}
          </Card.Text>
          <Link
            to={`/blog/${blog.id}`}
            className="text-dark text-decoration-none"
          >
            Read more <ArrowRight />
          </Link>
          <Card.Footer className="my-4 p-0"></Card.Footer>
        </Card.Body>
      </Card>
    </>
  );
}

export default BlogComponent;
// import React from "react";
// import { Card } from "react-bootstrap";
// import { ArrowRight } from "react-bootstrap-icons";
// import { Link } from "react-router-dom";
// import "../BlogComponent/BlogComponent.css";

// function BlogComponent({ blogData }) {
//   return (
//     <>
//       <Card>
//         <Card.Img variant="top" src={blogData.image && blogData.image.src} />
//         <Card.Text class="datetext">
//           {blogData.published_at.substring(0, 10)}
//         </Card.Text>
//         <Card.Body style={{ padding: "15px 0px" }}>
//           <Card.Title>{blogData.title}</Card.Title>
//           <Card.Text>{blogData.summary_html.substring(0, 100)}</Card.Text>
//           <Link
//             to={`/blog/${blogData.blog_id}/${blogData.id}`}
//             className="text-dark text-decoration-none"
//           >
//             Read more <ArrowRight />
//           </Link>
//           <Card.Footer className="my-4 p-0"></Card.Footer>
//         </Card.Body>
//       </Card>
//     </>
//   );
// }

// export default BlogComponent;
