import React from "react";
import { Spinner } from "react-bootstrap";

function SmallLoader() {
  return (
    <Spinner
      animation="grow"
      role="status"
      style={{
        height: "50px",
        width: "50px",
        margin: "auto",
        display: "block",
      }}
    ></Spinner>
  );
}

export default SmallLoader;
