import React, { useState } from "react";
import { Col, Row, Container, Form, Button, Image } from "react-bootstrap";
import { Facebook, Linkedin, Twitter, Clock } from "react-bootstrap-icons";
import { Email, Map, Phone, Pinterest } from "@material-ui/icons";
import Banner from "../components/Banner/Banner";
import Message from "../components/Message/Message";
import axios from "axios";

function ContactScreen() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [mailsent, setMailsent] = useState(false);
  const [error, setError] = useState(null);
  const [isSubmit, setIsSubmit] = useState(false);

  const obj = {
    name,
    phone,
    email,
    message,
    mailsent,
    error,
  };

  const API_PATH = "/email/index.php";

  const submitHandler = (e) => {
    e.preventDefault();
    // axios({
    //   method: "post",
    //   url: `${API_PATH}`,
    //   headers: { "content-type": "application/json" },
    //   data: mailsent,
    // });

    // .then((result) => {
    //   setMailsent(result.data.sent);
    // })
    // .catch((error) => setError(error.message));
    const config = {
      headers: {
        "Access-Control-Allow-Headers": "*",
        "Content-Type": "application/json",
      },
    };

    const { data } = axios.post(API_PATH, obj, config);

    console.log(data);
    console.log("Test");
  };
  return (
    <>
      {isSubmit && <Message varient="success">Form Submitted ✅</Message>}
      <Container>
        <Row className="p-3 contactp-0">
          <Col
            md={12}
            className="p-5 contactp-2 m-auto"
            style={{ width: "60%" }}
          >
            <Form
              onSubmit={() => setIsSubmit((prev) => !prev)}
              style={{ border: "2px solid", padding: "80px 50px" }}
              className="formContact"
              method="post"
              action="http://ezokshoes.com/email/index.php"
            >
              <Row className="mb-4">
                <Col>
                  <h2
                    className="Contact-headings"
                    style={{ fontWeight: "900" }}
                  >
                    Contact Us
                  </h2>
                  <div
                    style={{
                      height: "2px",
                      width: "100px",
                      background: "#C8A44C",
                      marginBottom: "10px",
                      marginTop: "10px",
                    }}
                  ></div>
                </Col>
              </Row>
              <Row className="pb-4">
                <Col>
                  <Form.Select
                    aria-label="Default select example"
                    name="test"
                    id="test"
                  >
                    <option>You are a</option>
                    <option value="Customer">Customer</option>
                    <option value="Trader">Trader</option>
                  </Form.Select>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Control
                      type="text"
                      placeholder="Name*"
                      className="contant-form"
                      onChange={(e) => setName(e.target.value)}
                      name="name"
                      controlId="name"
                      value={name}
                      required
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      type="phone"
                      placeholder="Phone*"
                      className="contant-form"
                      onChange={(e) => setPhone(e.target.value)}
                      name="phone"
                      controlId="phone"
                      value={phone}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      type="text"
                      placeholder="Email*"
                      className="contant-form"
                      onChange={(e) => setEmail(e.target.value)}
                      name="email"
                      controlId="email"
                      value={email}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Message*"
                      className="contant-form"
                      onChange={(e) => setMessage(e.target.value)}
                      name="message"
                      controlId="message"
                      value={message}
                      required
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Button
                variant="light"
                type="submit"
                className="rounded-0 custom-btn-buy"
                name="submit"
                id="submit"
              >
                Submit
              </Button>
              <div>{mailsent && <div>Thank you for contcting us.</div>}</div>
            </Form>
          </Col>
        </Row>
      </Container>
      <div
        style={{ background: "#e6e6e6", height: "15vh" }}
        className="p-3 adjheight"
      >
        <Container>
          <Row className="text-center ">
            <Col
              xs={12}
              md={3}
              style={{ display: "flex", justifyContent: "center" }}
              className="contactp-s2"
            >
              <div>
                <p
                  style={{
                    fontWeight: "900",
                    fontSize: "20px",
                    marginBottom: "5px",
                  }}
                >
                  <Email /> Email
                </p>

                <a
                  href="mailto: care@ezokshoes.com"
                  style={{ color: "#494949", textDecoration: "none" }}
                >
                  care@ezokshoes.com
                </a>
              </div>
            </Col>
            <Col
              xs={12}
              md={3}
              style={{ display: "flex", justifyContent: "center" }}
              className="contactp-s2"
            >
              <div>
                <p
                  style={{
                    fontWeight: "900",
                    fontSize: "20px",
                    marginBottom: "5px",
                  }}
                >
                  <Clock /> Timings
                </p>

                <a
                  href="mailto: care@ezokshoes.com"
                  style={{ color: "#494949", textDecoration: "none" }}
                >
                  Mon - Sat (9:00AM- 6:00PM )
                </a>
              </div>
            </Col>
            <Col
              xs={12}
              md={3}
              style={{ display: "flex", justifyContent: "center" }}
              className="contactp-s2"
            >
              <div>
                <p
                  style={{
                    fontWeight: "900",
                    fontSize: "20px",
                    marginBottom: "5px",
                  }}
                >
                  <Phone /> Customer care
                </p>

                <a
                  href="tel: +91-9310994491"
                  style={{
                    color: "#494949",
                    textDecoration: "none",
                    fontSize: "15px",
                  }}
                >
                  +91-9310994491 /{" "}
                </a>
                <a
                  href="tel: 9310993465"
                  style={{
                    color: "#494949",
                    textDecoration: "none",
                    fontSize: "15px",
                  }}
                >
                  9310993465
                </a>
              </div>
            </Col>
            <Col
              xs={12}
              md={3}
              style={{ display: "flex", justifyContent: "center" }}
              className="contactp-s2"
            >
              <div>
                <p
                  style={{
                    fontWeight: "900",
                    fontSize: "20px",
                    marginBottom: "5px",
                  }}
                >
                  <Map /> Address
                </p>
                <a
                  href="/"
                  style={{
                    color: "#494949",
                    textDecoration: "none",
                    fontSize: "15px",
                  }}
                >
                  C-7, Sector 80, Noida 201305 U.P.
                </a>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ContactScreen;
