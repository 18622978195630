import React, { useContext } from "react";
import { ShopContext } from "../../src/context/shopContext";
import { Container } from "react-bootstrap";
import InsideEzok from "../components/InsideEzok/InsideEzok";

function EzokInside() {
  const {} = useContext(ShopContext);

  return (
    <section
      style={{
        background: `url('/images/banners/pattern2.png')`,
        backgroundRepeat: "repeat",
      }}
    >
      <Container>
        <InsideEzok />
      </Container>
    </section>
  );
}

export default EzokInside;
