import React, { useEffect, useState } from "react";
import { ShopContext } from "../../src/context/shopContext";
import { Col, Container, Row, Card, ListGroup, Button } from "react-bootstrap";
import MyAccountNav from "../components/MyAccountNav/MyAccountNav";
import { Link } from "react-router-dom";
import QuantityComponent from "../components/QuantityComponent/QuantityComponent";
import Product from "../components/Product/Product";
import { Trash } from "react-bootstrap-icons";
import ProductWish from "../components/ProductWish/ProductWish";

function WishListScreen() {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = localStorage.getItem("EzokWishlist");
    if (fetchProducts !== null) {
      setProducts(JSON.parse(fetchProducts));
    }
  }, []);

  // const wishProducts = JSON.parse(localStorage.getItem("EzokWishlist"));

  const handleDelete = (product) => {
    const filteredProducts = products.filter((x) => x.id !== product.id);
    localStorage.setItem("EzokWishlist", JSON.stringify(filteredProducts));
    setProducts(filteredProducts);
  };

  console.log(products);

  return (
    <>
      <Container className="mb-5">
        <Row className="py-5 sideNav">
          <Col md={3}>
            <MyAccountNav />
          </Col>
          <Col md={1}></Col>
          <Col md={8}>
            <Container className="py-4">
              {products.length === 0 ? (
                <h3>Your WishList Is Empty</h3>
              ) : (
                <Row>
                  <h3>WishList</h3>
                  {products.map((pop) => (
                    <Col md={4}>
                      <Product product={pop} />
                      <Button
                        variant="secondary w-100 rounded-0"
                        onClick={() => handleDelete(pop)}
                      >
                        <Trash />
                      </Button>
                    </Col>
                  ))}
                </Row>
              )}
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default WishListScreen;
