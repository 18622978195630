import React, { useContext } from "react";
import { ShopContext } from "../../src/context/shopContext";
import { Col, Container, Row, Card, ListGroup } from "react-bootstrap";
import MyAccountNav from "../components/MyAccountNav/MyAccountNav";
import QuantityComponent from "../components/QuantityComponent/QuantityComponent";

function MyOrders() {
  const { checkout } = useContext(ShopContext);
  //   console.log(checkout);
  return (
    <>
      <Container>
        <Row className="py-5 sideNav">
          <Col md={3}>
            <MyAccountNav />
          </Col>
          <Col md={1}></Col>
          <Col md={8}>
            <Container className="py-4">
              <h3>Your Orders Are Empty</h3>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default MyOrders;
