import React from "react";
import { Carousel, Image } from "react-bootstrap";
import "../Carousal2/Carousal2.css";

function CarousalComponent2() {
  return (
    <section className="tipsection">
      <div className="text-center py-4 tipheadma">
        <h5 className="Custom-headings-home">Tip of the day</h5>
      </div>
      <div className="extrapad">
        <Carousel variant="dark" controls={false}>
          <Carousel.Item>
            <Carousel.Caption className="tipcarousal">
              <h3 style={{ color: "black" }}>Damp & Dry Clean Your Shoes</h3>
              <p style={{ color: "black" }}>
                Without water, it’s impossible to get the dirt out properly. So
                instead of soaking them in the water you can give them a damp
                wipe. Before doing this, make sure that you have removed all the
                excess dirt using a shoe brush. Now take any old, damp cloth and
                give your shoes a gentle wipe. This can be done daily if needed.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Carousel.Caption className="tipcarousal">
              <h3 style={{ color: "black" }}>Polishing The Shoes</h3>
              <p style={{ color: "black" }}>
                You need to polish the shoes in order to make them look as good
                as new. Polishing does nothing to the lifespan of the leather
                shoes. However, it does add shine and freshness to the shoes.
                You can polish them as and when necessary, using any variety of
                shoe polish.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
          <Carousel.Item>
            <Carousel.Caption className="tipcarousal">
              <h3 style={{ color: "black" }}>Keep Them Away From Water</h3>
              <p style={{ color: "black" }}>
                No matter what variety of leather you have, it is not going to
                be naturally waterproof. Water is like poison for not only
                leather but any premium material. In case your shoes do get
                exposed to water, make sure to dry them properly.
              </p>
            </Carousel.Caption>
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );
}

export default CarousalComponent2;
