import React from "react";
import { Container } from "react-bootstrap";

function ReturnPolicy() {
  return (
    <>
      <Container className="py-4">
        <h2 className="policy-heading">Returns & Refunds</h2>
        <div className="pt-4 pdiv" style={{ width: "80%", margin: "auto" }}>
          <p>
            {/* <h4>Return & Refund Policy</h4> */}
            Thanks for shopping at ezokshoes.com. If you are not entirely
            satisfied with your purchase, we're here to help.
            <br />
            <br />
            <h4>Return Policy</h4>
            You have 15 calendar days to return an item from the date you
            received it. To be eligible for a return, your item must be unused
            and in the same condition that you received it. Your item must be in
            the original packaging. Your item needs to have the receipt or proof
            of purchase.
            <br />
            <br />
            <h4>Refund Policy</h4>
            Once we receive your item, we will inspect it and notify you that we
            have received your returned item. We will immediately notify you on
            the status of your refund after inspecting the item. If your return
            is approved, we will initiate a refund to your account or credit
            card (or original method of payment). You will receive the credit
            within a certain amount of days, depending on your card issuer's
            policies.
            <br />
            <br />
            <h4>Shipping Policy</h4>
            Only in case of international customers, you will be responsible for
            paying for your own shipping costs for returning your item. Shipping
            costs are non-refundable. If you receive a refund, the cost of
            return shipping will be deducted from your refund. For all customers
            based in India, we shall get the products collected through our
            courier company. <br />
            <br />
            <h4>Contact Us </h4>
            If you have any questions on how to return your item, contact us.
            <br />
            <br />
          </p>
        </div>
      </Container>
    </>
  );
}

export default ReturnPolicy;
