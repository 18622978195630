import React from "react";
import { Carousel, Image } from "react-bootstrap";
import { isMobile } from "react-device-detect";

import "../Testimonails/Testimonial.css";

function Testimonails() {
  return (
    <section className="mt-3">
      <div className="text-center py-4 testheadma">
        <h5 className="Custom-headings-home">Testimonials</h5>
      </div>
      <div className="testdiv" style={{ textAlign: "center" }}>
        <Carousel variant="dark" className="testcarousal" controls={false}>
          <Carousel.Item>
            <Image
              src={
                isMobile
                  ? "images/testimonial/sidm.jpg"
                  : "images/testimonial/sid.jpg"
              }
              alt="First slide"
              fluid
              className="testimage"
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              src={
                isMobile
                  ? "images/testimonial/karm.jpg"
                  : "images/testimonial/kar.jpg"
              }
              alt="First slide"
              fluid
              className="testimage"
            />
          </Carousel.Item>
          <Carousel.Item>
            <Image
              src={
                isMobile
                  ? "images/testimonial/sidm.jpg"
                  : "images/testimonial/sid.jpg"
              }
              alt="First slide"
              fluid
              className="testimage"
            />
          </Carousel.Item>
        </Carousel>
      </div>
    </section>
  );
}

export default Testimonails;
