import React, { useState, useEffect, useContext } from "react";
import { ShopContext } from "../context/shopContext";
import { Container, Row, Col, ListGroup } from "react-bootstrap";
import Jumbotrons from "../components/Jumbotrons/Jumbotrons";
import Banner from "../components/Banner2/Banner";
import Loader from "../components/Loader/Loader";
import Product from "../components/Product/Product";

function CDress() {
  const {
    fetchCollections,
    collections,
    products,
    fetchAllProducts,
    singleCollection,
    fetchSingleCollection,
  } = useContext(ShopContext);

  const moccasinCollection =
    collections.length && collections.filter((x) => x.title === "Dress");
  const collectionId = String(collections.length && moccasinCollection[0].id);

  useEffect(() => {
    fetchCollections();
    fetchAllProducts();
    fetchSingleCollection(collectionId);
  }, [collectionId]);

  const customstyle = {
    maxWidth: "100%",
    maxHeight: "100%",
  };

  return (
    <>
      <Banner text="DRESS" />
      <Container className="mb-5">
        <Jumbotrons />
        <Row className="py-3 text-center">
          <Col>
            <h2 style={{ color: "#343434" }} className="Custom-headings">
              DRESS
            </h2>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Row xs={2} md={5}>
              {singleCollection.products ? (
                singleCollection.products.map((product) => (
                  <Col>
                    <Product product={product} styles={customstyle} />
                  </Col>
                ))
              ) : (
                <Loader />
              )}
            </Row>
          </Col>
        </Row>
      </Container>

      <Container className="mb-5">
        <Row className="py-3 text-center">
          <Col>
            <h2 style={{ color: "#343434" }} className="Custom-headings">
              COLLECTIONS
            </h2>
          </Col>
        </Row>

        <Row xs={2} sm={3} md={5}>
          {products.length === 0 ? (
            <Loader />
          ) : (
            products.map((product) => (
              <Col>
                <Product product={product} styles={customstyle} />
              </Col>
            ))
          )}
        </Row>
      </Container>
    </>
  );
}

export default CDress;
