import { Pinterest, Facebook, Twitter, Instagram } from "@material-ui/icons";
import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Linkedin } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import "../Footer/Footer.css";
import FooterTop from "../FooterTop/FooterTop";

function Footer() {
  return (
    <footer className="hidefooterpc">
      <FooterTop />
      <Container>
        <Row className="textcenterFooter padadjust">
          <Col xs={4} md={3} className="centerFooter notcenter">
            <Image
              src="/images/logo/logo.png"
              fluid
              className="whitelogo"
            ></Image>
          </Col>
          <Col xs={8} md={3}>
            <Row className="socialRow">
              <Col className="p-0">
                <Instagram className="footerIcons" />
              </Col>
              <Col className="p-0">
                <Facebook className="footerIcons" />
              </Col>
              <Col className="p-0">
                <Twitter className="footerIcons" />
              </Col>
              <Col className="p-0">
                <Pinterest className="footerIcons" />
              </Col>
              {/* <Col className="p-0">
                <Linkedin className="footerIcons footerIcons2" />
              </Col> */}
            </Row>
          </Col>
          {/* <Col
            xs={6}
            md={2}
            className="textcenterFooter footermarginbottom dispnone"
          >
            <Row className="mtopfooter">
              <Col xs={1} sm={3}>
                <p className="verticleText">INFORMATION</p>
              </Col>
              <Col xs={10} sm={9} className="p-0 ">
                <ul style={{ listStyle: "none" }} className="mobul">
                  <li>
                    <Link to="/about" className="footerLink">
                      About us
                    </Link>
                  </li>
                  <li>
                    <Link to="/ContactUs" className="footerLink">
                      Contact us
                    </Link>
                  </li>
                  <li>
                    <Link to="/ezokinside" className="footerLink">
                      Blog
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col> */}
          <Col xs={6} md={2} className="textcenterFooter">
            <Row className="mtopfooter">
              <Col xs={1} sm={3}>
                <p className="verticleText bebas">CUSTOMER SERVICE</p>
              </Col>
              <Col xs={10} sm={9} className="p-0">
                <ul style={{ listStyle: "none" }} className="mobul">
                  <li>
                    <Link to="/about" className="footerLink">
                      About
                    </Link>
                  </li>
                  <li>
                    <a
                      href="https://ezok-shoes.myshopify.com/account"
                      className="footerLink"
                      target="_blank"
                    >
                      Faqs
                    </a>
                  </li>
                  <li className="mobdispNode">
                    <Link to="/contactus" className="footerLink">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
          <Col xs={6} md={2} className="textcenterFooter">
            <Row className="mtopfooter">
              <Col xs={1} sm={3}>
                <p className="verticleText bebas">LEGAL POLICIES</p>
              </Col>
              <Col xs={10} sm={9} className="p-0">
                <ul style={{ listStyle: "none" }} className="mobul">
                  <li>
                    <Link to="/termsandconditions" className="footerLink">
                      T&C
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacypolicy" className="footerLink">
                      Privacy Policy
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/deliverypolicy" className="footerLink">
                      Delivery Policy
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/returnpolicy" className="footerLink">
                      Return Policy
                    </Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </footer>
  );
}

export default Footer;
