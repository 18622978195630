import React from "react";
import { Container } from "react-bootstrap";

function TandC() {
  return (
    <>
      <Container className="py-4">
        <h2 className="policy-heading">Terms and conditions</h2>
        <div className="pt-4 pdiv" style={{ width: "80%", margin: "auto" }}>
          <p>
            <h4>Delivery Charges:</h4>
            The delivery charges are included in the pricing, so please do not
            pay anything at the time of delivery.
            <br />
            <br />
            <h4>Ordering Policy</h4>
            <ol>
              <li>All Orders, booked and paid for, cannot be cancelled.</li>
              <li> Terms & Condition of service apply. </li>
              <li>
                For Order related queries, please contact Customer Service:
                care@ezokshoes.com
              </li>
            </ol>
            <br />
            <h4>Shipping Policy:</h4>
            We are committed to delivering your order accurately, in good
            condition, and on time. Please note our shipping policy is as
            follows:
            <ol>
              <li>
                We make our best efforts to ship each item in your order within
                5 working days of the order. However, in some cases, we may take
                longer, up to 7-10 working days.
              </li>
              <li>
                We ship on all weekdays (Monday to Saturday), excluding public
                holidays.
              </li>
              <li>
                Currently, each order may be shipped only to a single
                destination address. If you wish to ship products to different
                addresses, you shall need to place multiple orders.
              </li>
              <li>
                We only ship through reputed courier agencies to ensure that
                your order reaches you in the fastest time and in good
                condition.
              </li>
              <li>
                While we shall endeavour to ship all items in your order
                together, this may not always be possible due to product
                characteristics, or availability.
              </li>
            </ol>
            <br />
            <h4>Exchange Policy</h4>
            Ezok products are manufactured with the utmost care and stringent
            quality control is always maintained. However, despite this
            high-quality assurance, there can be few odd defective ones among
            the millions we manufacture and sell. In case you find a
            manufacturing defect in the product purchased, kindly contact
            Customer Service: care@ezokshoes.com
            <br />
            <br />
            We provide 100% replacement for major manufacturing defects if the
            concerned product is brought to our notice within 30 days from the
            date of invoice. Claims are entertained only against manufacturing
            defects and admissible when shoes have been appropriately used. All
            minor defects will be considered for repair first and not replaced.
            <br />
            <br />
            Definition of Major Manufacturing Defect:
            <ol>
              <li>Damaged/Discolored Upper Material</li>
              <li> Broken/Damaged Sole or Insole</li>
            </ol>
            <br />
            <h4>General Terms & Conditions for Exchange/Claims</h4>
            <ol>
              <li>
                Any issue or claim regarding delivery will be entertained up to
                a maximum of 30 days from the actual order delivery date. Ezok
                will not assume any responsibility or liability and will not
                entertain any issue or claim, for non-delivery after expiry of
                aforesaid 30 days period.
              </li>
              <li>
                All articles being returned for exchange/claim must be
                accompanied by original Invoice.
              </li>
              <li>
                No warranty/guarantee/exchange for articles purchased on
                discount or during sale period.
              </li>
              <li>
                Articles / Product sold under any promotional scheme including
                but not limited to discount coupons / gift coupons issued under
                any promotional scheme of Ezok or any third party will not be
                eligible for exchange & Claim/Refund.
              </li>
              <li>
                For exchange/claim, please contact Customer Service:
                care@ezokshoes.com{" "}
              </li>
              <li>
                For Exchange or Claim, it is mandatory to have the article
                inspected by Ezok and to return the article to our warehouse,
                upon approval the exchange/claim would be initiated.
              </li>
              <li>
                Replacement against exchange/claims will be subject to
                availability of the same product. In case, the same product is
                not available, then replacement will be adjusted against
                subsequent purchase at Ezok.
              </li>
              <li>
                The value of the replaced merchandise will be equal to the
                original purchase price of the exchanged/claimed article, as
                mentioned in the Invoice, irrespective of the current price of
                the exchanged/claimed article, at the time of the exchange /
                claim being made.
              </li>
              <li>
                If the value of the replaced article is less than the original
                value of the exchanged/claimed article, there will be no cash
                refund or credit note issued.
              </li>
              <li>
                If the value of the replaced article is more than the original
                value, Ezok will recover the difference against the sale.
              </li>
              <li>
                The decision of EZOK would be considered final in all cases.
              </li>
            </ol>
          </p>
        </div>
      </Container>
    </>
  );
}

export default TandC;
