import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

function FilterLoader() {
  const [display, setDisplay] = useState(false);

  useEffect(() => {
    setTimeout(function () {
      setDisplay(true);
    }, 5000);
  }, []);

  return (
    <>
      <Spinner
        animation="border"
        role="status"
        style={{
          height: "100px",
          width: "100px",
          margin: "100px auto",
          display: display ? "none" : "block",
        }}
      ></Spinner>

      <h4
        className="sr-only text-center mt-5"
        style={{ display: display ? "block" : "none", color: "#C8A44C" }}
      >
        No Products Found ....
        <a href="/collections2/all">reset filters</a>
      </h4>
    </>
  );
}

export default FilterLoader;
