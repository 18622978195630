import React from "react";
import { Container } from "react-bootstrap";

function DeliveryPolicy() {
  return (
    <>
      <Container className="py-4">
        <h2 className="policy-heading">Delivery Charges</h2>
        <div className="pt-4 pdiv" style={{ width: "80%", margin: "auto" }}>
          <p>
            The delivery charges are included in the pricing, so please do not
            pay anything at the time of delivery.
          </p>
          <br />
          <br />
          {/* <h5 style={{ textDecoration: "underline" }}>
            The delivery charges are included in the price, so you will not need
            to pay anything at the time of delivery.
          </h5> */}
        </div>
      </Container>
    </>
  );
}

export default DeliveryPolicy;
