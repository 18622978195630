import React, { useState, useEffect, useContext } from "react";
import { ShopContext } from "../context/shopContext";
import { Container, Row, Col, ListGroup, Button } from "react-bootstrap";
import Jumbotrons from "../components/Jumbotrons/Jumbotrons";
import Banner from "../components/Banner2/Banner";
import Product from "../components/Product/Product";
import Loader from "../components/Loader/Loader";
import SmallLoader from "../components/Loader/SmallLoader";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { ListNested } from "react-bootstrap-icons";

function Collections3({ match, location }) {
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [price, setPrice] = useState("");
  const [material, setMaterial] = useState("");
  const [sort, setSort] = useState("ID");
  const [query, setQuery] = useState("");
  const [filterDisp, setFilterDisp] = useState(true);
  const [fetchKeyword, setFetchKeyword] = useState("");
  const [headingName, setHeadingName] = useState("");
  const [smallLoad, setsmallLoad] = useState(false);
  const [collectionType, setCollectionType] = useState("");

  const type = match.params.type;

  const urlHeading = location.search.split("=")[1];

  console.log(urlHeading);

  console.log(location.search);

  useEffect(() => {
    if (type !== null) {
      setFetchKeyword(type);
    }

    if (urlHeading) {
      setHeadingName(urlHeading);
    }
  }, [type, urlHeading]);

  const {
    fetchCollections,
    collections,
    products,
    fetchAllProducts,
    singleCollection,
    fetchSingleCollection,
  } = useContext(ShopContext);

  const moccasinCollection =
    collections.length && collections.filter((x) => x.title === fetchKeyword);
  const collectionId = String(
    collections.length && moccasinCollection.length && moccasinCollection[0].id
  );

  useEffect(() => {
    fetchCollections();
    fetchAllProducts();
    fetchSingleCollection(collectionId);
  }, [collectionId, fetchKeyword]);

  const customstyle = {
    maxWidth: "100%",
    maxHeight: "100%",
  };

  const handleChange = (collect) => {
    setFetchKeyword(collect);
    setsmallLoad(true);
    setTimeout(() => {
      setsmallLoad(false);
    }, 1000);
  };

  return (
    <section
      style={{
        background: `url('/images/banners/pattern2.png')`,
        backgroundRepeat: "repeat",
      }}
    >
      <div
        style={{ backgroundColor: "black", height: "20vh" }}
        className="C3divheight"
      >
        <Container style={{ height: "100%" }}>
          <Row
            style={{
              height: "100%",
              color: "white",
              display: "flex",
              alignContent: "center",
            }}
          >
            <Col
              xs={12}
              md={3}
              className="text-center"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div>
                <h1 className="mensc3">MEN'S</h1>
              </div>
            </Col>
            <Col xs={12} md={9}>
              <Row className="text-center mb-4">
                <Col
                  xs={3}
                  style={{ borderRight: "1px solid white" }}
                  className="mnopadc3"
                >
                  <Link
                    onClick={() => {
                      handleChange("dress");
                      setHeadingName("DRESS SHOES");
                    }}
                    style={{ textDecoration: "none", color: "white" }}
                    className="bcat"
                  >
                    DRESS SHOES
                  </Link>
                </Col>
                <Col
                  xs={3}
                  style={{ borderRight: "1px solid white" }}
                  className="mnopadc3"
                >
                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    onClick={() => {
                      handleChange("moccasin");
                      setHeadingName("MOCASSINS");
                    }}
                    className="bcat"
                  >
                    MOCASSINS
                  </Link>
                </Col>
                <Col
                  xs={3}
                  style={{ borderRight: "1px solid white" }}
                  className="mnopadc3"
                >
                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    onClick={() => {
                      handleChange("loafers");
                      setHeadingName("LOAFERS");
                    }}
                    className="bcat"
                  >
                    LOAFERS
                  </Link>
                </Col>
                <Col xs={3} className="mnopadc3">
                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    onClick={() => {
                      handleChange("newarrivals");
                      setHeadingName("SNEAKERS");
                    }}
                    className="bcat"
                  >
                    SNEAKERS
                  </Link>
                </Col>
              </Row>
              <Row className="text-center">
                <Col
                  xs={3}
                  style={{ borderRight: "1px solid white" }}
                  className="mnopadc3"
                >
                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    onClick={() => {
                      handleChange("monkstraps");
                      setHeadingName("MONK STRAPS");
                    }}
                    className="bcat"
                  >
                    MONK STRAPS
                  </Link>
                </Col>
                <Col
                  xs={3}
                  style={{ borderRight: "1px solid white" }}
                  className="mnopadc3"
                >
                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    onClick={() => {
                      handleChange("casuals");
                      setHeadingName("CASUALS");
                    }}
                    className="bcat"
                  >
                    CASUALS
                  </Link>
                </Col>
                <Col
                  xs={3}
                  style={{ borderRight: "1px solid white" }}
                  className="mnopadc3"
                >
                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    onClick={() => {
                      handleChange("formals");
                      setHeadingName("FORMALS");
                    }}
                    className="bcat"
                  >
                    FORMALS
                  </Link>
                </Col>
                <Col xs={3} className="mnopadc3">
                  <Link
                    style={{ textDecoration: "none", color: "white" }}
                    onClick={() => {
                      handleChange("weddingspecials");
                      setHeadingName("WEDDING SPECIAL");
                    }}
                    className="bcat"
                  >
                    WEDDING SPECIAL
                  </Link>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>

      <Container className="mb-5">
        <Row
          className="py-3 text-center"
          style={{ borderBottom: "1px solid #ccc" }}
        >
          <Col>
            <h6 style={{ color: "#a3a3a3" }} className="C33h">
              <i>EXCLUSIVE DESIGNS</i>
            </h6>
          </Col>
          <Col>
            <h6 style={{ color: "#a3a3a3" }} className="C33h">
              <i>FREE SHIPPING</i>
            </h6>
          </Col>
          <Col>
            <h6 style={{ color: "#a3a3a3" }} className="C33h">
              <i>RETURN WITHIN 30 DAYS</i>
            </h6>
          </Col>
        </Row>
        <Row className="mt-2">
          <Col className="text-center colc3mdisp">
            <h5
              className="Custom-headings-collection-name"
              style={{ padding: "0", margin: "0" }}
            >
              {headingName}
            </h5>
            <div
              className="dispmc3"
              style={{
                height: "0.5px",
                width: "250px",
                background: "#C8A44C",
                marginTop: "0px",
                margin: "auto",
              }}
            ></div>
          </Col>
        </Row>
        {smallLoad && <SmallLoader />}
        <Row className="mt-3">
          <Col md={12}>
            <Row xs={2} md={5}>
              {singleCollection.products ? (
                singleCollection.products.map((product) => (
                  <Col>
                    <Product product={product} styles={customstyle} />
                  </Col>
                ))
              ) : (
                <Loader />
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Collections3;
