import React, { useContext, useEffect, useState } from "react";
import "../Cart/Cart.css";
import { ShopContext } from "../../context/shopContext";
import { Divider, Drawer } from "@material-ui/core";
import { Col, Container, Row, Image, ListGroup, Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";

const drawerWidth = 20;

const useStyles = makeStyles({
  page: {
    background: "#f9f9f9",
    width: "100%",
  },
  drawer: {
    width: drawerWidth,
  },
});

function Cart({ test }) {
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [price, setPrice] = useState("");
  const [material, setMaterial] = useState("");
  const [sort, setSort] = useState("ID");
  const [reverse, setReverse] = useState(false);
  const [query, setQuery] = useState("");
  const [filterDisp, setFilterDisp] = useState(true);
  const [smallLoad, setsmallLoad] = useState(false);

  const { isCartOpen, closeCart, checkout } = useContext(ShopContext);

  useEffect(() => {
    test(color, size, price, material, sort, reverse, smallLoad);
  }, [color, size, price, material, sort, reverse, smallLoad]);

  const handleSortR = (data) => {
    setReverse(true);
    setSort(data);
  };

  const handleSort = (data) => {
    setReverse(false);
    setSort(data);
  };

  const handleLoad = () => {
    setsmallLoad(true);
    setTimeout(() => {
      setsmallLoad(false);
    }, 2000);
  };

  return (
    <>
      <Drawer open={isCartOpen} onClose={closeCart} anchor="right">
        <Container className="py-4">
          <h3
            className="text-center mb-3 mx-auto"
            style={{ display: "inline-block" }}
          >
            Filters
          </h3>
          <Button
            className="btn btn-light"
            onClick={() => closeCart()}
            style={{ position: "absolute", left: "auto", right: "10px" }}
          >
            x
          </Button>
          <Row className="mt-3">
            <Col>
              <p className="filter-texts">Size </p>
              <ListGroup
                horizontal
                className="rounded-0"
                style={{ width: "200px" }}
              >
                <ListGroup.Item
                  onClick={() => {
                    setSize("tag:40");
                    handleLoad();
                  }}
                  action
                  style={{ marginRight: "10px" }}
                  className="rounded-0 border-1"
                >
                  40
                </ListGroup.Item>
                <ListGroup.Item
                  onClick={() => {
                    setSize("tag:41");
                    handleLoad();
                  }}
                  action
                  className="rounded-0 border-1"
                  style={{ marginRight: "10px" }}
                >
                  41
                </ListGroup.Item>
                <ListGroup.Item
                  onClick={() => {
                    setSize("tag:42");
                    handleLoad();
                  }}
                  action
                  className="rounded-0 border-1"
                  style={{ marginRight: "10px" }}
                >
                  42
                </ListGroup.Item>
                <ListGroup.Item
                  onClick={() => {
                    setSize("tag:43");
                    handleLoad();
                  }}
                  action
                  className="rounded-0 border-1"
                  style={{ marginRight: "10px" }}
                >
                  43
                </ListGroup.Item>
                <ListGroup.Item
                  onClick={() => {
                    setSize("tag:44");
                    handleLoad();
                  }}
                  action
                  className="rounded-0 border-1"
                  style={{ marginRight: "10px" }}
                >
                  44
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="filter-texts">Sort By</p>
              <ListGroup variant="flush" className="rounded-0">
                <ListGroup.Item
                  action
                  style={{ marginRight: "10px" }}
                  className="rounded-0"
                  onClick={() => {
                    {
                      handleSort("TITLE");
                      handleLoad();
                    }
                  }}
                >
                  Alphabetically: A-Z
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  style={{ marginRight: "10px" }}
                  className="rounded-0"
                  onClick={() => {
                    {
                      handleSortR("TITLE");
                      handleLoad();
                    }
                  }}
                >
                  Alphabetically: Z-A
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  className="rounded-0"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    {
                      handleSort("PRICE");
                      handleLoad();
                    }
                  }}
                >
                  Price: Low To High
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  className="rounded-0"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    handleSortR("PRICE");
                    handleLoad();
                  }}
                >
                  Price: High To Low
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  className="rounded-0"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    handleSort("CREATED_AT");
                    handleLoad();
                  }}
                >
                  Date: Old To New
                </ListGroup.Item>
                <ListGroup.Item
                  action
                  className="rounded-0"
                  style={{ marginRight: "10px" }}
                  onClick={() => {
                    handleSortR("CREATED_AT");
                    handleLoad();
                  }}
                >
                  Date: New To Old
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="filter-texts">Price</p>
              <ListGroup variant="flush" className="rounded-0">
                <ListGroup.Item
                  onClick={() => {
                    setPrice("AND tag:₹1999 - ₹2999");
                    handleLoad();
                  }}
                  action
                  style={{ marginRight: "10px" }}
                  className="rounded-0"
                >
                  ₹1999 - ₹2999
                </ListGroup.Item>
                <ListGroup.Item
                  onClick={() => {
                    setPrice("AND tag:₹2199 - ₹2999");
                    handleLoad();
                  }}
                  action
                  className="rounded-0"
                  style={{ marginRight: "10px" }}
                >
                  ₹2199 - ₹2999
                </ListGroup.Item>
                <ListGroup.Item
                  onClick={() => {
                    setPrice("AND tag:₹2999 - ₹3999");
                    handleLoad();
                  }}
                  action
                  className="rounded-0"
                  style={{ marginRight: "10px" }}
                >
                  ₹2999 - ₹3999
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col>
              <p className="filter-texts">Color </p>
              <ListGroup
                horizontal
                className="rounded-0"
                style={{ width: "200px" }}
              >
                <ListGroup.Item
                  onClick={() => {
                    setColor("AND tag:black");
                    handleLoad();
                  }}
                  action
                  style={{
                    backgroundColor: "black",
                    marginRight: "10px",
                    borderRadius: "50%",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;
                </ListGroup.Item>

                <ListGroup.Item
                  onClick={() => {
                    setColor("AND tag:tan");
                    handleLoad();
                  }}
                  action
                  style={{
                    backgroundColor: "tan",
                    marginRight: "10px",
                    borderRadius: "50%",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;
                </ListGroup.Item>
                <ListGroup.Item
                  onClick={() => {
                    setColor("AND tag:brown");
                    handleLoad();
                  }}
                  action
                  style={{
                    backgroundColor: "brown",
                    marginRight: "10px",
                    borderRadius: "50%",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;
                </ListGroup.Item>
                <ListGroup.Item
                  onClick={() => {
                    setColor("AND tag:#4c0013");
                    handleLoad();
                  }}
                  action
                  style={{
                    backgroundColor: "#4c0013",
                    marginRight: "10px",
                    borderRadius: "50%",
                  }}
                >
                  &nbsp;&nbsp;&nbsp;
                </ListGroup.Item>
                {/* <ListGroup.Item
                    onClick={() => setColor("AND tag:#423636")}
                    action
                    className="rounded-0"
                    style={{ backgroundColor: "#423636", marginRight: "10px" }}
                  >
                    &nbsp;&nbsp;&nbsp;
                  </ListGroup.Item> */}
              </ListGroup>
            </Col>
          </Row>

          {/* <Row className="mt-3">
            <Col>
              <p className="filter-texts">Material </p>
              <ListGroup variant="flush" className="rounded-0">
                <ListGroup.Item
                  onClick={() => setMaterial("AND tag:Leather")}
                  action
                  style={{ marginRight: "10px" }}
                  className="rounded-0"
                >
                  Leather
                </ListGroup.Item>
              </ListGroup>
            </Col>
          </Row> */}
          {/* <Row className="mt-3">
            <Col>
              <Button
                className="btn btn-dark rounded-0 w-100"
                onClick={() => closeCart()}
              >
                APPLY
              </Button>
            </Col>
          </Row> */}
        </Container>
      </Drawer>
    </>
  );
}

export default Cart;
