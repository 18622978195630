import React from "react";
import { Container } from "react-bootstrap";

function FAQ() {
  return (
    <>
      <Container className="py-4">
        <h2 className="policy-heading">Frequently Asked Questions</h2>
        <div className="pt-4 pdiv" style={{ width: "80%", margin: "auto" }}>
          <p>
            <h4>DO YOU HAVE A WARRANTY ON YOUR FOOTWEAR?</h4>
            Yes, we do. It entitles you to return your order on account for up
            to 15 days from the date of delivery. To avail the service, please
            ensure that your specified reason for replacement is covered under
            the ‘terms and conditions’ under our warranty.
            <br />
            <br />
            <h4>HOW QUICKLY WILL MY ORDER SHIP?</h4>
            In order to ensure your order is shipped out the same day, please
            place your order by 2pm, from Monday to Friday. We do not ship on
            weekends.
            <br />
            <br />
            <h4>WHERE DO YOU SHIP FROM?</h4>
            Packages are shipped from NOIDA, Uttar Pradesh.
            <br />
            <br />
            <h4>DO YOU OFFER DELIVERY ON WEEKENDS?</h4>
            We are off on weekends. Product will be dispatched on weekends.
            <br />
            <br />
            <h4>DO YOU SHIP INTERNATIONALLY?</h4>
            Yes, Your order can be purchased and shipped internationally.
            Contact us at <a href="care@ezokshoes.com">
              care@ezokshoes.com
            </a>{" "}
            for more information.
            <br />
            <br />
            <h4>
              HOW DO I BECOME AN EZOK RETAILER OR INTERNATIONAL DISTRIBUTOR?
            </h4>
            Kindly email your name, phone number, store/distribution name,
            store/distribution address to 
            <a href="admin@ezokshoes.com">admin@ezokshoes.com</a>
            <br />
            <br />
            <h4>DO YOU HAVE REPLACEMENT LACES AND INSOLES AVAILABLE?</h4>
            Yes, we do provide replacement of defective laces and insoles.
            Please share a clear image of the product along with its style
            specific information (printed on the inside upper) and your shipping
            address to <a href="care@ezokshoes.com">care@ezokshoes.com</a>
            <br />
            <br />
            <h4>WHAT ALL PAYMENT MODES ARE AVAILABLE?</h4>
            You can choose from the following methods of payment when shopping
            with Ezokshoes.com
            <ul>
              <li>
                Credit card/Debit card - Payment via all credit/debit card is
                available
              </li>
              <li>
                Internet Banking - Pay on our website using Net Banking Facility
              </li>
              <li>Wallets & UPIs - Pay via Virtual wallet or UPI methods</li>
              <li>Cash on Delivery - Pay in Cash at the time of delivery.</li>
            </ul>
            <br />
            <br />
            <h4>IS THE PAYMENT SAFE AND SECURED?</h4>
            Absolutely! Our payment partners use the highest level of secure
            encryption technology to make your transaction safe and
            confidential. You can choose your preferred method of payment when
            you are prompted to choose a ‘Payment Option’ in the buying process.
            <br />
            <br />
            <h4>IS THERE ANY HIDDEN EXTRA CHARGES?</h4>
            Absolutely not! The price that you see on our website is the final
            price that you have to pay, inclusive of all taxes. You will be
            provided with a tax invoice along with your order.
            <br />
            <br />
          </p>
        </div>
      </Container>
    </>
  );
}

export default FAQ;
