import React, { useState, useEffect, useContext } from "react";
import { ShopContext } from "../context/shopContext";
import { Container, Row, Col, ListGroup, Button } from "react-bootstrap";
import Jumbotrons from "../components/Jumbotrons/Jumbotrons";
import Banner from "../components/Banner2/Banner";
import Product from "../components/Product/Product";
import FilterLoader from "../components/Loader/FilterLoader";
import SmallLoader from "../components/Loader/SmallLoader";
import { LinkContainer } from "react-router-bootstrap";
import { Link } from "react-router-dom";
import { ListNested } from "react-bootstrap-icons";

function Collections({ match }) {
  const [color, setColor] = useState("");
  const [size, setSize] = useState("");
  const [price, setPrice] = useState("");
  const [material, setMaterial] = useState("");
  const [sort, setSort] = useState("ID");
  const [reverse, setReverse] = useState(false);
  const [query, setQuery] = useState("");
  const [filterDisp, setFilterDisp] = useState(true);
  const [smallLoad, setsmallLoad] = useState(false);
  // const [lining, setLining] = useState("");

  // const keyword = match.params.keyword;
  // console.log(keyword);

  const {
    fetchCollections,
    collections,
    products,
    fetchAllProducts,
    fetchFilterProducts,
    filteredProducts,
    fetchFilterSingleCollection,
    filterCollection,
  } = useContext(ShopContext);

  useEffect(() => {
    fetchFilterProducts(query, sort, reverse);
    fetchCollections();
    fetchAllProducts();
    fetchFilterSingleCollection();
  }, [query, sort, reverse]);

  useEffect(() => {
    // const query = {
    //   sortKey: "PRICE",
    //   query: "tag:44 AND tag:₹2999-₹3999 AND tag:black",
    // };

    setQuery(size + " " + color + " " + price);
  }, [color, size, price]);

  // console.log(color);
  // console.log(size);
  // console.log(price);
  // console.log(query);

  const customstyle = {
    maxWidth: "100%",
    maxHeight: "100%",
  };

  const handleSortR = (data) => {
    setReverse(true);
    setSort(data);
  };

  const handleSort = (data) => {
    setReverse(false);
    setSort(data);
  };

  const handleLoad = () => {
    setsmallLoad(true);
    setTimeout(() => {
      setsmallLoad(false);
    }, 2000);
  };

  return (
    <>
      <Banner
        text="COLLECTIONS"
        url="https://cdn.shopify.com/s/files/1/0570/6370/2695/files/1._Banner-1920x1080.jpg?v=1627035089"
      />
      <Container className="mb-5">
        <Jumbotrons />
        <Row className="py-3 text-center">
          <Col>
            <h2 style={{ color: "#343434" }} className="Custom-headings">
              COLLECTIONS
            </h2>
          </Col>
        </Row>
        {smallLoad && <SmallLoader />}
        <Row>
          <Col>
            <Button
              variant="light"
              className="rounded-0 custom-btn-buy filterbtn"
              onClick={() => setFilterDisp((prevState) => !prevState)}
              style={{ display: "none" }}
            >
              Filter <ListNested />
            </Button>
          </Col>
        </Row>
        <Row>
          <Col md={3} className={filterDisp && "filterDisplay"}>
            <Row className="mt-3">
              <Col>
                <p className="filter-texts">Size </p>
                <ListGroup
                  horizontal
                  className="rounded-0"
                  style={{ width: "200px" }}
                >
                  <ListGroup.Item
                    onClick={() => {
                      setSize("tag:40");
                      handleLoad();
                    }}
                    action
                    style={{ marginRight: "10px" }}
                    className="rounded-0 border-1"
                  >
                    40
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={() => {
                      setSize("tag:41");
                      handleLoad();
                    }}
                    action
                    className="rounded-0 border-1"
                    style={{ marginRight: "10px" }}
                  >
                    41
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={() => {
                      setSize("tag:42");
                      handleLoad();
                    }}
                    action
                    className="rounded-0 border-1"
                    style={{ marginRight: "10px" }}
                  >
                    42
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={() => {
                      setSize("tag:43");
                      handleLoad();
                    }}
                    action
                    className="rounded-0 border-1"
                    style={{ marginRight: "10px" }}
                  >
                    43
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={() => {
                      setSize("tag:44");
                      handleLoad();
                    }}
                    action
                    className="rounded-0 border-1"
                    style={{ marginRight: "10px" }}
                  >
                    44
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p className="filter-texts">Sort By</p>
                <ListGroup variant="flush" className="rounded-0">
                  <ListGroup.Item
                    action
                    style={{ marginRight: "10px" }}
                    className="rounded-0"
                    onClick={() => {
                      handleSort("TITLE");
                      handleLoad();
                    }}
                  >
                    Alphabetically: A-Z
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    style={{ marginRight: "10px" }}
                    className="rounded-0"
                    onClick={() => {
                      handleSortR("TITLE");
                      handleLoad();
                    }}
                  >
                    Alphabetically: Z-A
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    className="rounded-0"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      handleSort("PRICE");
                      handleLoad();
                    }}
                  >
                    Price: Low To High
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    className="rounded-0"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      handleSortR("PRICE");
                      handleLoad();
                    }}
                  >
                    Price: High To Low
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    className="rounded-0"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      handleSort("CREATED_AT");
                      handleLoad();
                    }}
                  >
                    Date: Old To New
                  </ListGroup.Item>
                  <ListGroup.Item
                    action
                    className="rounded-0"
                    style={{ marginRight: "10px" }}
                    onClick={() => {
                      handleSortR("CREATED_AT");
                      handleLoad();
                    }}
                  >
                    Date: New To Old
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p className="filter-texts-price">Price Filter</p>
                <ListGroup variant="flush" className="rounded-0">
                  <ListGroup.Item
                    onClick={() => {
                      setPrice("AND tag:₹1999 - ₹2999");
                      handleLoad();
                    }}
                    action
                    style={{ marginRight: "10px" }}
                    className="rounded-0"
                  >
                    ₹1999 - ₹2999
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={() => {
                      setPrice("AND tag:₹2199 - ₹2999");
                      handleLoad();
                    }}
                    action
                    className="rounded-0"
                    style={{ marginRight: "10px" }}
                  >
                    ₹2199 - ₹2999
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={() => {
                      setPrice("AND tag:₹2999 - ₹3999");
                      handleLoad();
                    }}
                    action
                    className="rounded-0"
                    style={{ marginRight: "10px" }}
                  >
                    ₹2999 - ₹3999
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p className="filter-texts">Color </p>
                <ListGroup
                  horizontal
                  className="rounded-0"
                  style={{ width: "200px" }}
                >
                  <ListGroup.Item
                    onClick={() => {
                      setColor("AND tag:black");
                      handleLoad();
                    }}
                    action
                    style={{
                      backgroundColor: "black",
                      marginRight: "10px",
                      borderRadius: "50%",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;
                  </ListGroup.Item>

                  <ListGroup.Item
                    onClick={() => {
                      setColor("AND tag:tan");
                      handleLoad();
                    }}
                    action
                    style={{
                      backgroundColor: "tan",
                      marginRight: "10px",
                      borderRadius: "50%",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={() => {
                      setColor("AND tag:brown");
                      handleLoad();
                    }}
                    action
                    style={{
                      backgroundColor: "brown",
                      marginRight: "10px",
                      borderRadius: "50%",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;
                  </ListGroup.Item>
                  <ListGroup.Item
                    onClick={() => {
                      setColor("AND tag:#4c0013");
                      handleLoad();
                    }}
                    action
                    style={{
                      backgroundColor: "#4c0013",
                      marginRight: "10px",
                      borderRadius: "50%",
                    }}
                  >
                    &nbsp;&nbsp;&nbsp;
                  </ListGroup.Item>
                  {/* <ListGroup.Item
                    onClick={() => setColor("AND tag:#423636")}
                    action
                    className="rounded-0"
                    style={{ backgroundColor: "#423636", marginRight: "10px" }}
                  >
                    &nbsp;&nbsp;&nbsp;
                  </ListGroup.Item> */}
                </ListGroup>
              </Col>
            </Row>

            {/* <Row className="mt-3">
              <Col>
                <p className="filter-texts">Material </p>
                <ListGroup variant="flush" className="rounded-0">
                  <ListGroup.Item
                    onClick={() => setMaterial("AND tag:Leather")}
                    action
                    style={{ marginRight: "10px" }}
                    className="rounded-0"
                  >
                    Leather
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row> */}

            {/* <Row className="mt-3">
              <Col>
                <p className="filter-texts">Lining</p>
                <ListGroup variant="flush" className="rounded-0">
                  <ListGroup.Item
                    action
                    style={{ marginRight: "10px" }}
                    className="rounded-0"
                  >
                    Leather
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p className="filter-texts">Sole</p>
                <ListGroup variant="flush" className="rounded-0">
                  <ListGroup.Item
                    action
                    style={{ marginRight: "10px" }}
                    className="rounded-0"
                  >
                    Leather
                  </ListGroup.Item>
                </ListGroup>
              </Col>
            </Row> */}
          </Col>
          <Col md={1}></Col>
          <Col md={8}>
            <Row>
              {filteredProducts.length === 0 ? (
                <FilterLoader />
              ) : (
                filteredProducts.map((product) => (
                  <Col xs={6} md={4} key={product.id}>
                    <Product product={product} styles={customstyle} />
                  </Col>
                ))
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default Collections;
