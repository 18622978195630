import React from "react";
import { Row, Col, Image, Container } from "react-bootstrap";
import Banner from "../components/Banner/Banner";

function About() {
  return (
    <section
      style={{
        background: `url('/images/banners/pattern2.png')`,
        backgroundRepeat: "repeat",
      }}
    >
      <Banner
        text="About Us"
        url="https://cdn.shopify.com/s/files/1/0570/6370/2695/files/iStock-824027176_c495795d-b650-411d-a29f-8fc3eb504543.jpg?v=1625118544"
      />
      <Container>
        <Row style={{ background: "#e6e6e6" }}>
          <Col className="p-5 paadjust">
            <h5
              style={{ width: "80%", margin: "auto" }}
              className="mparaa wadjustabout"
            >
              Ezok is handcrafted, pure leather shoe brand with an exquisite
              range of curated products. Ezok creates premium, trendy and fine
              quality footwear that is contemporary as well as affordable.
            </h5>
          </Col>
        </Row>
        <div className="pt-5 about-div">
          <Row>
            <Col>
              <h3
                className="pb-2 about-subhead mb-4 fontheada"
                style={{ fontWeight: "900" }}
              >
                Shoes That Have It All
              </h3>

              <p style={{ color: "#7e7e7e" }} className="about-para mb-5 h225">
                Our shoes are contemporary, sturdy and strong. Made from the
                finest quality leather and immaculately crafted by artisans,
                they exude a class of their own. For everyday use or for special
                occasion, Ezok has a wide range to offer for one and all.
                <br className="dispmaboutO" />
                <br className="dispmaboutO" />
              </p>
              <Image
                src="/images/about/about2.jpg"
                fluid
                className="about-img-cards mb-5"
              />
            </Col>
            <Col>
              <h3
                className="pb-2 about-subhead mb-4 fontheada"
                style={{ fontWeight: "900" }}
              >
                Reach Compliant
                {/* <br className="dispmabout" />
                <br className="dispmabout" /> */}
              </h3>

              <p style={{ color: "#7e7e7e" }} className="about-para mb-5 h225">
                Our company adheres to the REACH Compliant (The European Union
                Chemical Regulation governing the Registration, Evaluation,
                Authorization and Restrictions of Chemicals). REACH is a dynamic
                regulation that places onus on industry to manage risks that
                chemicals may pose to health and environment.
              </p>
              <Image
                src="/images/about/about1.jpg"
                fluid
                className="about-img-cards mb-5"
              />
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
}

export default About;
