import axios from "axios";
import React, { useEffect, useState } from "react";
import Client from "shopify-buy";

const ShopContext = React.createContext();

const client = Client.buildClient({
  storefrontAccessToken: "7ff84451ab79122fc6bf499512ebc5d2",
  domain: "ezok-shoes.myshopify.com",
});

function ShopProvider({ children }) {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [collections, setCollections] = useState({});
  const [singleCollection, setSingleCollection] = useState({});
  const [checkout, setCheckout] = useState({});
  const [isCartOpen, setIsCartopen] = useState(false);
  const [blogs, setBlogs] = useState({});
  const [singleBlog, setSingleBlog] = useState({});
  const [articles, setArticles] = useState({});
  const [singleArticle, setSingleArticle] = useState({});
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [filterCollection, setFilterCollection] = useState([]);

  useEffect(() => {
    createCheckout();
  }, []);

  const createCheckout = async () => {
    const checkoutData = await client.checkout.create();
    setCheckout(checkoutData);
  };

  const addItemToCheckout = async (variantId, quantity) => {
    const lineItemsToAdd = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];

    const checkoutData = await client.checkout.addLineItems(
      checkout.id,
      lineItemsToAdd
    );

    setCheckout(checkoutData);
  };

  const updateItemToCheckout = async (id, quantity) => {
    const lineItemsToUpdate = [
      {
        id,
        quantity: parseInt(quantity, 10),
      },
    ];

    const checkoutData = await client.checkout.updateLineItems(
      checkout.id,
      lineItemsToUpdate
    );

    setCheckout(checkoutData);
  };

  const removeItemToCheckout = async (id) => {
    const lineItemIdsToRemove = [
      {
        id,
      },
    ];

    const checkoutData = await client.checkout.removeLineItems(
      checkout.id,
      lineItemIdsToRemove
    );

    setCheckout(checkoutData);
  };

  const fetchAllProducts = async () => {
    const productsData = await client.product.fetchAll();
    setProducts(productsData);
  };

  const fetchFilterProducts = async (queryI, sortI, reverseI) => {
    const query = {
      sortKey: sortI,
      query: queryI,
      reverse: reverseI,
    };

    const productsData = await client.product.fetchQuery(query);
    setFilteredProducts(productsData);
  };

  const fetchProductWithId = async (id) => {
    const singleProductData = await client.product.fetch(id);
    setProduct(singleProductData);
  };

  const fetchCollections = async () => {
    const productsData = await client.collection.fetchAllWithProducts();
    setCollections(productsData);
  };

  const fetchSingleCollection = async (collectionId) => {
    const productsData = await client.collection.fetchWithProducts(
      collectionId
    );
    setSingleCollection(productsData);
  };

  const fetchFilterSingleCollection = async () => {
    const query = {
      first: 5,
      query: "title:'Dress'",
    };
    // query: "tag:44 AND tag:₹2999-₹3999 AND tag:black",
    const productsData = await client.collection.fetchQuery(500);

    setFilterCollection(productsData);
  };

  const closeCart = () => {
    setIsCartopen(false);
  };
  const openCart = () => {
    setIsCartopen(true);
  };

  // Blogs

  const fetchBlogs = async () => {
    const config = {
      auth: {
        username: "bb4d4122dae58ca340d16428a6417754",
        password: "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",
      },
    };

    const { data } = await axios.get("/admin/api/2021-07/blogs.json", config);

    setBlogs(data);
  };

  const fetchSingleBlog = async (id) => {
    const config = {
      auth: {
        username: "bb4d4122dae58ca340d16428a6417754",
        password: "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",
      },
    };

    const { data } = await axios.get(
      `/admin/api/2021-07/blogs/${id}.json`,
      config
    );

    setSingleBlog(data);
  };

  const fetchArticles = async (id) => {
    const config = {
      auth: {
        username: "bb4d4122dae58ca340d16428a6417754",
        password: "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",
      },
    };
    let test = String(id);

    const { data } = await axios.get(
      `/admin/api/2021-07/blogs/${test}/articles.json`,
      config
    );

    setArticles(data);
  };

  const fetchSingleArticle = async (blogId, articleId) => {
    const config = {
      auth: {
        username: "bb4d4122dae58ca340d16428a6417754",
        password: "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",
      },
    };

    const { data } = await axios.get(
      `/admin/api/2021-07/blogs/${blogId}/articles/${articleId}.json`,
      config
    );

    setSingleArticle(data);
  };

  return (
    <ShopContext.Provider
      value={{
        products,
        product,
        checkout,
        isCartOpen,
        collections,
        singleCollection,
        blogs,
        singleBlog,
        articles,
        singleArticle,
        filteredProducts,
        filterCollection,
        fetchAllProducts,
        fetchProductWithId,
        closeCart,
        openCart,
        addItemToCheckout,
        removeItemToCheckout,
        updateItemToCheckout,
        fetchCollections,
        fetchSingleCollection,
        fetchBlogs,
        fetchSingleBlog,
        fetchArticles,
        fetchSingleArticle,
        fetchFilterProducts,
        fetchFilterSingleCollection,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
}

const ShopConsumer = ShopContext.Consumer;

export { ShopConsumer, ShopContext };

export default ShopProvider;
