import React, { useContext, useEffect, useState } from "react";
import { ShopContext } from "../../src/context/shopContext";
import {
  Col,
  Container,
  Row,
  Card,
  ListGroup,
  Image,
  Form,
  FormControl,
  Modal,
  Button,
} from "react-bootstrap";
import MyAccountNav from "../components/MyAccountNav/MyAccountNav";
import QuantityComponent from "../components/QuantityComponent/QuantityComponent";
import { Link } from "react-router-dom";
import axios from "axios";
import Message from "../components/Message/Message";

function CartScreen({ history }) {
  const [cartItemsLocal, setCartItemsLocal] = useState({});
  const [modalshow, setModalShow] = useState(false);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isloggedin, setIsloggedin] = useState(true);

  const handleModelClose = () => setModalShow(false);
  const handleModelShow = () => setModalShow(true);

  const { checkout } = useContext(ShopContext);

  const token = localStorage.getItem("token");

  useEffect(() => {
    setIsloggedin(true);
    if (token == null) {
      setIsloggedin(false);
    }
  }, [token]);

  let test = checkout.lineItems !== cartItemsLocal;
  console.log(test);

  // console.log(JSON.parse(cartItemsLocal));

  useEffect(() => {
    if (checkout.lineItems !== cartItemsLocal) {
      localStorage.setItem("ezokcart", JSON.stringify(checkout.lineItems));
    }
  }, [checkout]);

  useEffect(() => {
    // const cartData = localStorage.getItem("ezokcart");
    // const cartDataParsed = JSON.parse(cartData && cartData);
    const cartData = localStorage.getItem("ezokcart");

    setCartItemsLocal(cartData);
  }, []);

  const checkLogin = (e) => {
    if (isloggedin === true) {
      window.location.href = checkout.webUrl;
    } else {
      // console.log(checkout.lineItems);
      // alert("Please login to checkout.");
      // history.push("/login");
      handleModelShow();
    }
  };

  console.log(cartItemsLocal);

  const submitForm = (e) => {
    e.preventDefault();
    var UniqueID = Date.now();
    var ClientIDdata = Date.now();
    var ApiKeygen = (UniqueID + ClientIDdata).toString();

    document.getElementById("otp").type = "hidden";
    if (mobile == "") {
      setErrMsg("Please enter mobile number");
      return false;
    } else if (isNaN(mobile)) {
      setErrMsg("Please enter numbers only");
      return false;
    } else if (mobile.length != 10) {
      setErrMsg("Please enter 10 digits numbers only");
      return false;
    } else {
      document.getElementById("otp").type = "number";
      setErrMsg("");
    }

    if (otp == "") {
      setErrMsg("Please enter otp number");
      return false;
    } else if (isNaN(otp)) {
      setErrMsg("Please enter otp numbers only");
      return false;
    } else if (otp.length != 4) {
      setErrMsg("Enter 4 digits otp");
      return false;
    }

    var apidata = { phone: mobile };
    // call api
    axios.post("/loginsignup", apidata).then((res) => {
      var _resData = res.data;
      var _status = _resData.status;
      var _statusMessage = _resData.message;
      if (_status == 200) {
        setErrMsg("");
        localStorage.setItem("ezokuserid", _resData.data);
        localStorage.setItem("token", ApiKeygen);
        setIsloggedin(true);
      } else {
        setErrMsg(_statusMessage);
      }
    });
    handleModelClose();
    // window.location.href = "/userdashboard";
  };

  return (
    <>
      <Container>
        <Row className="py-5 sideNav">
          <Col md={3}>
            <MyAccountNav />
          </Col>
          <Col md={1}></Col>
          <Col md={8}>
            <Container className="py-4">
              {checkout.lineItems && checkout.lineItems.length === 0 ? (
                <h3>Your Shopping Cart Is Empty</h3>
              ) : (
                <div className="d-flex flex-column mb-4">
                  <h1>My Cart</h1>
                  <Row>
                    {checkout.lineItems &&
                      checkout.lineItems.map((item) => (
                        <Col md={4}>
                          <Card style={{ height: "auto" }}>
                            <Card.Img
                              variant="top"
                              src={item.variant.image.src}
                              // style={{
                              //   objectFit: "cover",
                              //   maxWidth: "270px",
                              //   maxHeight: "200px",
                              //   verticalAlign: "middle",
                              // }}
                            />
                            <Card.Body
                              style={{ background: "#e6e6e6" }}
                              className="card-body"
                            >
                              <Row>
                                <Col>
                                  <Card.Text
                                    className="m-0 cardkatext"
                                    style={{ fontFamily: "sans-serif" }}
                                  >
                                    {item.title}
                                  </Card.Text>
                                  <Card.Text
                                    className="m-0"
                                    style={{ fontFamily: "sans-serif" }}
                                  >
                                    <strong>₹{item.variant.price}</strong>
                                  </Card.Text>
                                </Col>
                                <Col
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "flex-end",
                                  }}
                                >
                                  <Card.Text className="m-0">
                                    <QuantityComponent
                                      itemQty={item.quantity}
                                      variantId={item.variant.id}
                                      itemId={item.id}
                                    />
                                  </Card.Text>
                                </Col>
                              </Row>
                            </Card.Body>
                            {/* <Card.Text style={{ textAlign: "right" }}>
                            <Button
                              variant="secondary"
                              className="p-0"
                              onClick={removeItemToCheckout(item.id)}
                            >
                              Delete Item
                            </Button>
                          </Card.Text> */}
                          </Card>
                        </Col>
                      ))}
                  </Row>
                  <Row className="pt-4">
                    <Col md={4}>
                      <ListGroup variant="flush">
                        <ListGroup.Item>
                          <p>
                            Subtotal (
                            {checkout.lineItems && checkout.lineItems.length}{" "}
                            Items)
                          </p>
                        </ListGroup.Item>
                        <ListGroup.Item>
                          <p style={{ padding: "0", margin: "0" }}>
                            ₹{checkout.totalPrice}
                          </p>
                          <p style={{ color: "#999999", fontSize: "12px" }}>
                            Price inclusive of all taxes
                          </p>
                        </ListGroup.Item>
                      </ListGroup>
                    </Col>
                    <Col md={4}>
                      <Link
                        to="/collections2/all"
                        className="btn col-12 custom-btn-buy"
                      >
                        Continue Shopping
                      </Link>
                    </Col>
                    <Col md={4} className="mcartbtns">
                      <Link
                        onClick={() => {
                          checkLogin();
                        }}
                        className="btn col-12 custom-btn-buy"
                      >
                        Proceed To Pay
                      </Link>
                    </Col>
                  </Row>
                </div>
              )}
            </Container>
          </Col>
        </Row>
      </Container>

      {/* Modal login */}
      <Modal show={modalshow} onHide={handleModelClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Image
              src="/images/logo/logoF.png"
              alt="img"
              fluid
              className="navlogo"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errMsg && <Message varient="danger">{errMsg}</Message>}
          <Container>
            <Row className="contactp-0">
              <Col md={12} className="contactp-3 m-auto">
                <Form
                  onSubmit={(e) => submitForm(e)}
                  style={{ padding: "20px 0px" }}
                  className="formContact"
                  method="post"
                  action="http://ezokshoes.com/email/index.php"
                >
                  <Row className="mb-4">
                    <Col>
                      <h2
                        className="Contact-headings"
                        style={{ fontWeight: "900" }}
                      >
                        Login
                      </h2>
                      <div
                        style={{
                          height: "2px",
                          width: "100px",
                          background: "#C8A44C",
                          marginBottom: "10px",
                          marginTop: "10px",
                        }}
                      ></div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="number"
                          maxLength="10"
                          name="mobile"
                          id="mobile"
                          placeHolder="Enter Mobile*"
                          className="contant-form"
                          onChange={(e) => setMobile(e.target.value)}
                          value={mobile}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="hidden"
                          placeHolder="OTP"
                          maxLength="4"
                          name="otp"
                          id="otp"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    variant="light"
                    type="submit"
                    className="rounded-0 custom-btn-buy"
                    name="submit"
                    id="submit"
                  >
                    Submit
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModelClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default CartScreen;
