import React from "react";
import "../Banner2/Banner2.css";

function Banner({ text }) {
  return (
    <>
      <div style={{ background: "black", height: "15vh" }} className="bc2">
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <h1
            style={{ color: "white", fontWeight: "900" }}
            className="about-head headban2"
          >
            {text}
          </h1>
        </div>
      </div>
    </>
  );
}

export default Banner;
