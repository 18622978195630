import React, { useState, useEffect } from "react";

import { Image } from "react-bootstrap";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";
import "../Carousal/Carousal.css";

import SwiperCore, {
  Mousewheel,
  Navigation,
  Pagination,
  Autoplay,
} from "swiper/core";

SwiperCore.use([Mousewheel, Pagination, Navigation, Autoplay]);

function CarousalComponent() {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;

  return (
    <>
      <Swiper
        direction={"horizontal"}
        spaceBetween={isMobile ? 0 : 20}
        className="mySwiper"
        pagination={{ clickable: true }}
        // autoplay={{ delay: "2000" }}
        loop
        // slidesPerView={isMobile ? 1 : 1.2}
        slidesPerView={1}
        navigation
      >
        <SwiperSlide>
          <img
            className=" w-100"
            src={isMobile ? "images/banners/bm1.jpg" : "images/banners2/1.jpg"}
            alt="First slide"
          />

          {/* <div className="caption-box hidePhoneBannerText"> */}
          {/* <div className="C-logo">
              <Image src="images/logo/logo.png" fluid className="bannerLogo" />
            </div> */}
          {/* <div className="C-title">
              <h1 className="bebas">
                The <br />
                <span className="shoespan">Shoe</span>
                <br />
                Must Go On
              </h1>
            </div>
          </div> */}
        </SwiperSlide>
        <SwiperSlide>
          <img
            className=" w-100"
            src={isMobile ? "images/banners/bm22.jpg" : "images/banners2/2.jpg"}
            alt="First slide"
          />
          {/* <div className="caption-box3 hidePhoneBannerText"> */}
          {/* <div className="C-logo2">
              <Image src="images/logo/logo.png" fluid className="bannerLogo" />
            </div> */}
          {/* <div className="C-title3">
              <h1 className="bebas">
                If you ever
                <br /> Look down,
                <br /> it should be <br /> to only admire
                <br />
                your shoes!
              </h1>
            </div>
          </div> */}
        </SwiperSlide>
        <SwiperSlide>
          <img
            className=" w-100"
            src={isMobile ? "images/banners/bm3.jpg" : "images/banners2/3.jpg"}
            alt="First slide"
          />
          {/* <div className="caption-box hidePhoneBannerText"> */}
          {/* <div className="C-logo">
              <Image src="images/logo/logo.png" fluid className="bannerLogo" />
            </div> */}
          {/* <div className="C-title4">
              <h1 className="bebas">
                The <br />
                <span className="shoespan">Shoe</span>
                <br />
                Must Go On
              </h1>
            </div>
          </div> */}
        </SwiperSlide>
        <SwiperSlide>
          <img
            className=" w-100"
            src={isMobile ? "images/banners/bm4.jpg" : "images/banners2/7.jpg"}
            alt="First slide"
          />
          {/* <div className="caption-box3 hidePhoneBannerText"> */}
          {/* <div className="C-logo3">
              <Image src="images/logo/logo.png" fluid className="bannerLogo" />
            </div> */}
          {/* <div className="C-title3">
              <h1 className="bebas">
                Good
                <br className="bmadjust3" /> Flexion
                <br className="bmadjust31" /> Provides
                <br className="bmadjust312" /> breathability,
                <br className="bmadjust312" /> stability & <br />
                durablity
              </h1>
            </div>
          </div> */}
        </SwiperSlide>
        <SwiperSlide>
          <img
            className=" w-100"
            src={isMobile ? "images/banners/bm52.jpg" : "images/banners2/5.jpg"}
            alt="First slide"
          />
          {/* <div className="caption-box3 hidePhoneBannerText"> */}
          {/* <div className="C-logo3">
              <Image src="images/logo/logo.png" fluid className="bannerLogo" />
            </div> */}
          {/* <div className="C-title6">
              <h1 className="bebas">
                FLEXIBILITY <br />
                IS THE KEY
                <br className="bmadjust312" />
                TO STABILITY
                <br /> <span style={{ fontSize: "2vw" }}>- JOHN Wooden</span>
              </h1>
            </div>
          </div> */}
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default CarousalComponent;
