import React, { useEffect, useContext, useState } from "react";
import {
  Col,
  Row,
  Image,
  Button,
  Tab,
  ListGroup,
  Modal,
} from "react-bootstrap";
import { ShopContext } from "../context/shopContext";
import Message from "../components/Message/Message";
import { Link } from "react-router-dom";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.min.css";
import "swiper/components/pagination/pagination.min.css";
import "swiper/components/navigation/navigation.min.css";

import SwiperCore, {
  Mousewheel,
  Navigation,
  Pagination,
  Autoplay,
} from "swiper/core";
import { Rulers } from "react-bootstrap-icons";
import Loader from "../components/Loader/Loader";
import { isMobile } from "react-device-detect";

SwiperCore.use([Mousewheel, Pagination, Navigation, Autoplay]);

function ProductsDetails({ match, history }) {
  const [size, setSize] = useState();
  const [color, setColor] = useState("");
  const [addedToWish, setAddedToWish] = useState(false);
  const [key, setKey] = useState("");
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  let productId = match.params.id;

  const { fetchProductWithId, addItemToCheckout, product } =
    useContext(ShopContext);

  useEffect(() => {
    fetchProductWithId(productId);
    setAddedToWish(false);
    return () => {};
  }, [productId, key]);

  const sizeArr =
    product.options && product.options.filter((opt) => opt.name === "Size");
  const colorArr =
    product.options && product.options.filter((opt) => opt.name === "Color");

  const handleVariantSize = (variantSize) => {
    setSize(variantSize);
    // alert(variantSize + " / " + color);
  };
  const handleVariantColor = (variantColor) => {
    setColor(variantColor);
    // alert(size + " / " + variantColor);
  };

  const selectedVar =
    product.variants &&
    product.variants.filter((x) => x.title === size + " / " + color);

  const wishListVar = selectedVar;

  const AddToWishList = (pop) => {
    const wish = JSON.parse(localStorage.getItem("EzokWishlist"));

    if (wish !== null) {
      const existItem = wish.filter((x) => x.id === pop.id);

      if (existItem.length !== 0) {
        setAddedToWish(true);
      } else {
        wish.push(pop);
        localStorage.setItem("EzokWishlist", JSON.stringify(wish));
      }
    } else {
      localStorage.setItem("EzokWishlist", JSON.stringify([pop]));
    }
    setAddedToWish(true);
  };
  console.log(product);
  // console.log(key);

  return (
    <>
      {addedToWish && <Message varient="success">Added to WishList</Message>}
      {!product.title ? (
        <Loader />
      ) : (
        <Row>
          <Col md={6}>
            <Tab.Container
              id="list-group-tabs-example"
              activeKey={key === "" ? "#" + product.images[0].id : key}
              onSelect={(k) => setKey(k)}
            >
              <Row>
                <Col xs={3} sm={3} className="swiperPDpage">
                  <Swiper
                    direction={"vertical"}
                    spaceBetween={0}
                    slidesPerView={3.5}
                    style={{ height: "80vh" }}
                    className="mobtabsview"
                    mousewheel
                    navigation={isMobile}
                  >
                    <ListGroup>
                      {product.images.map((img) => (
                        <SwiperSlide key={img.id}>
                          <ListGroup.Item action href={"#" + img.id}>
                            <Image src={img.src} fluid></Image>
                          </ListGroup.Item>
                        </SwiperSlide>
                      ))}
                    </ListGroup>
                  </Swiper>
                </Col>
                <Col xs={9} sm={9}>
                  <Tab.Content>
                    {product.images.map((img) => (
                      <Tab.Pane key={img.id} eventKey={"#" + img.id}>
                        <Image src={img.src} fluid />
                      </Tab.Pane>
                    ))}
                  </Tab.Content>
                </Col>
              </Row>
            </Tab.Container>
          </Col>

          <Col md={6} className="p-5 phoneHide">
            <Row>
              <Col>
                <h3 style={{ margin: "0", padding: "0" }}>
                  Rs. {product.variants[0].price}
                </h3>
                <p style={{ color: "#999999", fontSize: "12px" }}>
                  Inclusive of all taxes
                </p>
                <h5 style={{ color: "#C8A54E" }}>{product.title}</h5>
              </Col>
            </Row>
            <Row>
              <Col md={6}>
                <p style={{ margin: "0", padding: "0" }}>Size</p>

                <ListGroup
                  horizontal
                  className="rounded-0"
                  style={{ width: "100px" }}
                >
                  {sizeArr[0].values.map((val) => (
                    <ListGroup.Item
                      className="rounded-0"
                      action
                      variant="light"
                      onClick={() => handleVariantSize(val.value)}
                    >
                      {val.value}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>
              <Col
                md={6}
                style={{
                  display: "flex",

                  alignItems: "center",
                  marginTop: "20px",
                }}
                className="mpadpd"
              >
                <Button
                  // href="https://ezokshoes.com/images/chart.png"
                  className="btn btn-outline-dark rounded-0 btn-sm"
                  variant="light"
                  target="_blank"
                  rel="noreferrer"
                  onClick={handleShow}
                >
                  <Rulers /> Sizing Guide
                </Button>
                <Modal show={show} onHide={handleClose} size="lg" centered>
                  <Modal.Header closeButton>
                    <Modal.Title>Sizing Guide</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <Image
                      src="https://ezokshoes.com/images2/chart.png"
                      fluid
                    />
                  </Modal.Body>
                  <Modal.Footer>
                    <Button
                      variant="secondary"
                      className="rounded-0"
                      onClick={handleClose}
                    >
                      Close
                    </Button>
                    {/* <Button variant="primary" onClick={handleClose}>
                      Save Changes
                    </Button> */}
                  </Modal.Footer>
                </Modal>
              </Col>
            </Row>
            <Row className="py-3">
              <Col md={6}>
                <p style={{ margin: "0", padding: "0" }}>Color</p>

                <ListGroup
                  horizontal
                  className="rounded-0"
                  style={{ width: "100px" }}
                >
                  {colorArr[0].values.map((val) => (
                    <ListGroup.Item
                      className="rounded-0"
                      style={{
                        background: val.value,
                        height: "30px",
                        width: "50px",
                        marginRight: "20px",
                      }}
                      onClick={() => handleVariantColor(val.value)}
                    >
                      {/* {val.value} */}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>
              <Col
                md={12}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                className="mpadpd mpadpd2"
              >
                <span style={{ color: "#C8A54E" }}>Size: </span> {size}{" "}
                &nbsp;/&nbsp;
                <span style={{ color: "#C8A54E" }}>Color: </span> {color}
              </Col>
            </Row>

            <Button
              className="rounded-0 btn-dark"
              onClick={() => {
                selectedVar.length === 0
                  ? alert("Select Size & Color")
                  : addItemToCheckout(selectedVar[0].id, 1);
                selectedVar.length !== 0 && history.push("/cart");
              }}
              // disabled={selectedVar.length === 0 ? true : false}
            >
              Add to cart
            </Button>
            <Button
              className={`m-2 rounded-0 btn-secondary ${
                selectedVar.length === 0 && isMobile && "btn-sm"
              }`}
              onClick={() => AddToWishList(product)}
            >
              Add to Wishlist
            </Button>
            <Row className="mt-2">
              <Col md={12}>
                <p
                  dangerouslySetInnerHTML={{ __html: product.descriptionHtml }}
                />
              </Col>
            </Row>
          </Col>
          <Col md={6} className="p-5 pcHide">
            <Row>
              <Col>
                <h3 style={{ margin: "0", padding: "0" }}>
                  Rs. {product.variants[0].price}/-
                </h3>
                <p style={{ color: "#999999", fontSize: "12px" }}>
                  Inclusive of all taxes
                </p>
                <h5 style={{ color: "#C8A54E" }}>{product.title}</h5>
                {/* <p>{product.description}</p> */}
              </Col>
            </Row>
            <Row>
              <Col xs={6} className="pb-2">
                <p style={{ padding: "0", margin: "0" }}>Color</p>
                <ListGroup
                  horizontal
                  className="rounded-0"
                  style={{ width: "100px" }}
                >
                  {colorArr[0].values.map((val) => (
                    <ListGroup.Item
                      className="rounded-0"
                      style={{
                        background: val.value,
                        height: "30px",
                        width: "50px",
                        marginRight: "20px",
                      }}
                      onClick={() => handleVariantColor(val.value)}
                    >
                      {/* {val.value} */}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>
              <Col
                xs={6}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                className="mpadpd mpadpd2"
              >
                <Button
                  // href="https://ezokshoes.com/images/chart.png"
                  className="btn btn-outline-dark rounded-0 btn-sm"
                  variant="light"
                  onClick={handleShow}
                >
                  <Rulers /> Sizing Guide
                </Button>
              </Col>

              {/* <Col
                md={6}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
                className="mpadpd"
              >
                <a
                  href="https://ezokshoes.com/images/chart.png"
                  className="btn btn-outline-dark rounded-0 btn-sm"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Rulers /> Sizing Guide
                </a>
              </Col> */}
            </Row>
            <Row className="pb-3">
              <Col md={6}>
                <p style={{ padding: "0", margin: "0" }}>Size</p>
                <ListGroup
                  horizontal
                  className="rounded-0"
                  style={{ width: "100px" }}
                >
                  {sizeArr[0].values.map((val) => (
                    <ListGroup.Item
                      className="rounded-0"
                      action
                      variant="light"
                      onClick={() => handleVariantSize(val.value)}
                    >
                      {val.value}
                    </ListGroup.Item>
                  ))}
                </ListGroup>
              </Col>
              <Col
                xs={7}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                className="mpadpd mpadpd2"
              >
                <span style={{ color: "rgb(200, 164, 76)" }}>Size:</span> {size}{" "}
                / <span style={{ color: "rgb(200, 164, 76)" }}>Color:</span>{" "}
                {color}
              </Col>
              {/* <Col
                xs={5}
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                className="mpadpd mpadpd2"
              >
                
                <Button
                 
                  className="btn btn-outline-dark rounded-0 btn-sm"
                  variant="light"
                  onClick={handleShow}
                >
                  <Rulers /> Sizing Guide
                </Button>
              </Col> */}
            </Row>

            <Button
              className="rounded-0 btn-dark"
              onClick={() => {
                selectedVar.length === 0
                  ? alert("Select Size & Color")
                  : addItemToCheckout(selectedVar[0].id, 1);
                selectedVar.length !== 0 && history.push("/cart");
              }}
              // disabled={selectedVar.length === 0 ? true : false}
            >
              {/* {selectedVar.length === 0 ? "Select Color & Size" : "Add to cart"} */}
              Add to cart
            </Button>
            <Button
              className="m-2 rounded-0 btn-secondary"
              onClick={() => AddToWishList(product)}
            >
              Add to Wishlist
            </Button>
            <Col xs={12} className="pt-2">
              <p>{product.description}</p>
            </Col>
          </Col>
        </Row>
      )}
    </>
  );
}

export default ProductsDetails;
