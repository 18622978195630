import React, { useContext, useState } from "react";
import {
  Nav,
  Navbar,
  Container,
  Image,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { ShopContext } from "../../context/shopContext";
import "../Header/Header.css";
import { Bag, Heart, Person, Search, Cart } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import SearchBox from "../SearchBox";
import { Route } from "react-router-dom";

function Header() {
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };
  const { openCart } = useContext(ShopContext);

  return (
    <header>
      <Navbar className="nav-bg" expand="lg" collapseOnSelect fixed="top">
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <Image
                src="/images/logo/logoF.png"
                alt="img"
                fluid
                className="navlogo"
              />
            </Navbar.Brand>
          </LinkContainer>

          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mx-auto">
              <LinkContainer to="/">
                <Nav.Link>Home</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/collections/newarrivals">
                <Nav.Link>New Arrivals</Nav.Link>
              </LinkContainer>
              <NavDropdown
                title="Shop by Category"
                id="basic-nav-dropdown"
                show={show}
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}
              >
                <LinkContainer to="/collections/dress">
                  <NavDropdown.Item>Dress Shoes</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/collections/comfort">
                  <NavDropdown.Item>Formal Shoes</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/collections/moccasin">
                  <NavDropdown.Item>Moccasin Shoes</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/collections/flexi">
                  <NavDropdown.Item>Flexi Shoes</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/collections/newarrivals">
                  <NavDropdown.Item>New Arrivals</NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/collections/bestseller">
                  <NavDropdown.Item>Best Seller</NavDropdown.Item>
                </LinkContainer>
                {/* <NavDropdown.Item href="#action/3.3">Casuals</NavDropdown.Item>
                <NavDropdown.Item href="#action/3.3">Loafers</NavDropdown.Item> */}
                <NavDropdown.Divider />
                <LinkContainer to="/collections/all">
                  <NavDropdown.Item>View All</NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>
              {/* <LinkContainer to="/Clearance">
                <Nav.Link>Clearance </Nav.Link>
              </LinkContainer> */}
              <LinkContainer to="/ezokinside">
                <Nav.Link>Blogs</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about">
                <Nav.Link>About Us</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/ContactUs">
                <Nav.Link>Contact Us</Nav.Link>
              </LinkContainer>
              {/* <LinkContainer
                to="/rEACHcompliance
"
              >
                <Nav.Link>REACH compliance</Nav.Link>
              </LinkContainer> */}
            </Nav>
            <Nav className="ms-auto">
              <Route
                render={({ history }) => <SearchBox history={history} />}
              />

              {/* <Form className="d-flex w-100 ms-auto" action="/search">
                <Link className="customInputButton" to="/search">
                  <Search />
                </Link>
                <FormControl
                  type="search"
                  placeholder="Find Your Style"
                  className="mr-2 customInput"
                  aria-label="Search"
                ></FormControl>
              </Form> */}
              {/* <LinkContainer to="/products">
                <Nav.Link>
                  <Person />
                </Nav.Link>
              </LinkContainer> */}

              <LinkContainer to="/wishlist">
                <Nav.Link>
                  <Heart />
                </Nav.Link>
              </LinkContainer>

              {/* <Nav.Link>
                <Bag onClick={() => openCart()} />
              </Nav.Link> */}
              <LinkContainer to="/cart">
                <Nav.Link>
                  <Bag />
                </Nav.Link>
              </LinkContainer>
              {/* <Nav.Link>
                <MenuIcon onClick={() => openCart()} />
              </Nav.Link> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
