import React from "react";
import "../Banner/Banner.css";

function Banner({ url, text }) {
  return (
    <>
      <div className="bannerContainer" style={{ background: `url(${url})` }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <h1
            style={{ color: "white", fontWeight: "900" }}
            className="about-head"
          >
            {text}
          </h1>
        </div>
      </div>
    </>
  );
}

export default Banner;
