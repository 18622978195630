import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import "../Jumbotrons/Jumbotrons.css";

function Jumbotrons() {
  return (
    <>
      <Row className="jumbosection">
        <Col xs={4}>
          <Row>
            <Col md={3} className="jumboimg-container">
              <Image src="/images/icons/j1.jpg" className="jumbo-img"></Image>
            </Col>
            <Col
              md={9}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h5
                className="jumbofont"
                style={{ textTransform: "uppercase", fontWeight: "400" }}
              >
                Premium Leather
              </h5>
              <p className="jumbopara">Hand Picked Exquisite Leather</p>
            </Col>
          </Row>
        </Col>
        <Col xs={4} className="jumboimg-container">
          <Row>
            <Col md={3}>
              <Image src="/images/icons/j2.jpg" className="jumbo-img"></Image>
            </Col>
            <Col
              md={9}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h5
                className="jumbofont"
                style={{ textTransform: "uppercase", fontWeight: "400" }}
              >
                Handmade By Experts
              </h5>
              <p className="jumbopara">Industry's Finest Shoemakers</p>
            </Col>
          </Row>
        </Col>
        <Col xs={4} className="jumboimg-container">
          <Row>
            <Col md={3}>
              <Image src="/images/icons/j3.jpg" className="jumbo-img"></Image>
            </Col>
            <Col
              md={9}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h5
                className="jumbofont"
                style={{ textTransform: "uppercase", fontWeight: "400" }}
              >
                Sustainably crafted
              </h5>
              <p className="jumbopara">Safe for Communities and Consumers</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Jumbotrons;
