import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";

function Loader() {
  return (
    <>
      <Spinner
        animation="border"
        role="status"
        style={{
          height: "100px",
          width: "100px",
          margin: "100px auto",
          display: "block",
        }}
      ></Spinner>
    </>
  );
}

export default Loader;
