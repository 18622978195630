import React, { useContext, useState } from "react";
import {
  Nav,
  Navbar,
  Container,
  Image,
  Form,
  FormControl,
  NavDropdown,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { ShopContext } from "../../context/shopContext";
import "../Header2/Header2.css";
import {
  Bag,
  Heart,
  Person,
  Search,
  Cart,
  Phone,
  Telephone,
} from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import SearchBox from "../SearchBox";
import { Route } from "react-router-dom";
import SearchComponent from "../SearchComponent/SearchComponent";

function Header3m() {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { openCart } = useContext(ShopContext);

  return (
    <header>
      <Navbar bg="light" expand="lg" collapseOnSelect fixed="top">
        <Container className="header-container">
          <LinkContainer to="/">
            <Navbar.Brand>
              <Image
                src="/images/logo/logoF.png"
                alt="img"
                fluid
                className="navlogo"
              />
            </Navbar.Brand>
          </LinkContainer>
          {/* <LinkContainer to="/">
            <Navbar.Brand className="mdisplaynav">
              <Image
                src="/images/logo/logoF.png"
                alt="img"
                fluid
                className="navlogo"
              />
            </Navbar.Brand>
          </LinkContainer> */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <LinkContainer to="/collections2/all">
                <Nav.Link className="px-4">SHOP</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about">
                <Nav.Link className="px-5">ABOUT US</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/ezokinside">
                <Nav.Link className="px-4">BLOGS</Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>

          <Navbar.Collapse id="basic-navbar-nav" style={{ width: "10%" }}>
            <Nav className="ms-auto">
              {/* <div className="mdisplaynavnone">
                <Route
                  render={({ history }) => <SearchBox history={history} />}
                />
              </div> */}

              {/* <Nav.Link onClick={() => handleShow()}>
                <Search />
              </Nav.Link> */}

              <LinkContainer to="/collections3/all">
                <Nav.Link>
                  <Search />
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contactus">
                <Nav.Link>
                  <Telephone />
                </Nav.Link>
              </LinkContainer>

              <Nav.Link
              // href="https://ezok-shoes.myshopify.com/account"
              // target="_blank"
              // onClick={() => handleShow()}
              >
                <Person style={{ color: "#686868" }} />
              </Nav.Link>

              {/* <LinkContainer to="/wishlist">
                <Nav.Link>
                  <Search />
                </Nav.Link>
              </LinkContainer> */}
              <LinkContainer to="/wishlist">
                <Nav.Link>
                  <Heart />
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/cart">
                <Nav.Link>
                  <Bag />
                </Nav.Link>
              </LinkContainer>
              <div className="mdisplaynav">
                <Route
                  render={({ history }) => <SearchBox history={history} />}
                />
              </div>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* <Route
        render={({ history }) => (
          <SearchComponent
            show={show}
            handleClose={handleClose}
            history={history}
          />
        )}
      /> */}
    </header>
  );
}

export default Header3m;
