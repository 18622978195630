import React from "react";
import { Card, Col, Row } from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import "../Blogs/Blogs.css";

function Blogs() {
  return (
    <section>
      <div className="text-center py-3 mt-3 oublogsheadma">
        <h5 className="Custom-headings-home">Our Blogs</h5>
      </div>
      <Row className="blogRow">
        <Col md={6} className="blogCol">
          <Card className="blogCard">
            <Card.Img variant="top" src="images/blogs/blog-n2.jpg" />
            <Card.Body>
              <Card.Title className="bebas titlecardsblogs">
                Flexibility and Comfort
              </Card.Title>
              <Card.Text>
                The principal concern with the footwear is poor fit.
              </Card.Text>
              <Link to="/ezokinside" style={{ color: "black" }}>
                Read More&nbsp;
                <ArrowRight />
              </Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6} className="blogCol">
          <Card className="blogCard">
            <Card.Img
              variant="top"
              src="https://cdn.shopify.com/s/files/1/0570/6370/2695/articles/1.jpg?v=1627709280"
            />
            <Card.Body>
              <Card.Title className="bebas titlecardsblogs">
                Handmade with perfection
              </Card.Title>
              <Card.Text>
                Our footwear crafting story commenced at the end of last
                century, when we started manufacturing hand.....
              </Card.Text>
              <Link to="/ezokinside" style={{ color: "black" }}>
                Read More&nbsp;
                <ArrowRight />
              </Link>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </section>
  );
}

export default Blogs;
