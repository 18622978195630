import React from "react";
import { Row, Col } from "react-bootstrap";
import BlogComponent from "../BlogComponent/BlogComponent";

function InsideBlogs({ blogData }) {
  return (
    <>
      <Row>
        {blogData.map((blog) => (
          <Col md={6} key={blog.id}>
            <BlogComponent blog={blog} />
          </Col>
        ))}
      </Row>
    </>
  );
}

export default InsideBlogs;

// import React from "react";
// import { Row, Col } from "react-bootstrap";
// import BlogComponent from "../BlogComponent/BlogComponent";

// function InsideBlogs({ blogData }) {
//   return (
//     <>
//       <Row>
//         <Col md={4} key={blogData.id}>
//           <BlogComponent blog={blogData} />
//         </Col>
//       </Row>
//     </>
//   );
// }

// export default InsideBlogs;
