import React from "react";
import { Container } from "react-bootstrap";

function PrivacyPolicy() {
  return (
    <>
      <Container className="py-4">
        <h2 className="policy-heading">Privacy Policy</h2>
        <div className="pt-4 pdiv" style={{ width: "80%", margin: "auto" }}>
          <p>
            <span>
              EZOK and its subsidiaries and divisions, including the website,
              respect your privacy. This Privacy Policy outlines the manner your
              data is collected and used by Ezok. You are advised to please read
              the Privacy Policy carefully. By accessing the services provided
              by Ezok you agree to the collection and use of your data by Ezok
              in the manner provided in this Privacy Policy.
            </span>
          </p>
          <p>
            <span>
              If you have questions or concerns regarding this statement, you
              can contact us at +91-9310994491 / 9310993465 or email us at{" "}
            </span>
            <span>care@ezokshoes.com</span>
            <span>
              . The Policy does not apply to the procedures and practices
              followed by entities that are not managed, owned or controlled by
              Ezok or to the people that are not engaged, employed or managed by
              Ezok.
            </span>
          </p>
          <p>
            <b>
              <span>Through this document, we wish to make you:</span>
            </b>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>Feel at ease while using our websites</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>Feel confident while sharing information with us</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>
              Aware that you may contact us with your questions or concerns
              about privacy on this website
            </span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>
              Aware that by using our websites you are agreeing to the
              collection of certain data
            </span>
          </p>
          <p>
            <b>
              <span>Information that may be collected from you…</span>
            </b>
          </p>
          <p>
            <span>
              Ezok collects the details provided by you on registration together
              with information we learn about you from your use of our service
              and your visits to our website and other websites accessible from
              them.
            </span>
          </p>
          <p>
            <span>
              Kindly, note that Ezok does not access or store your Payment Card
              details (i.e. credit/debit card number, expiration date, CVV
              etc.). When you make a purchase using your card, all required
              transaction details are captured on the secured payment page, and
              encrypted using Industrial Strength Cipher, and are securely
              transmitted to your card issuer for obtaining an authorization
              decision. At no time during the purchase process or thereafter
              does Ezok have access to, or store, your complete card account
              information.
            </span>
          </p>
          <p>
            <span>
              We may collect additional information in connection with your
              participation in any promotions or contests offered by us and
              information you provide when giving us feedback or completing
              profile forms. We also monitor customer traffic patterns and
              website use, which enables us to improve the service we provide.
              We will collect only such information as is necessary and relevant
              to us to provide you with the services available on the website.
            </span>
          </p>
          <p>
            <span>
              While you use our website, we may have automatic access to
              (receive and collect) certain anonymous information in standard
              usage logs through our web server, obtained from “cookies” sent to
              your browser from web server cookies stored on your hard drive,
              including but not limited to:
            </span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>Computer-identification information.</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>IP address, assigned to the computer which you use.</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>The domain server through which you access our service.</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>The type of computer you’re using.</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>The type of web browser you’re using.</span>
          </p>
          <br />
          <p>
            <span>
              While you are transacting on our website personally identifiable
              information collected about you may include:
            </span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>First and last name.</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>Email addresses.</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>Contact details including phone numbers.</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>PIN/ZIP code.</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>
              Demographic profile (like your age, gender, occupation, education,
              address and durables owned).
            </span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>
              Personal preferences and interests (such as books, movies, music
              and so on); and
            </span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>
              Your opinion on services, products, features on our websites.
            </span>
          </p>
          <p></p>
          <p>
            <span>We may also collect the following information about:</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>The pages you visit/access</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>The links you click on our website</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>The number of times you access the page</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>Things you view, add to bag, add to wish list</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>
              Other websites you open simultaneously while browsing on ours When
              / How do we collect the information?
            </span>
          </p>
          <p></p>
          <p>
            <span>
              We will collect anonymous traffic information from you when you
              visit our website.
            </span>
          </p>
          <p>
            <span>
              We collect the personally identifiable information from you when
              you register with us or you transact as a guest. During
              registration you are required to give us your contact information
              (such as name, email address, date of birth, gender, billing
              address, delivery address, pin code, mobile number, occupation,
              interests etc.) Upon registration users may receive communications
              from Ezok (e.g. newsletters, updates). Even when you transact as a
              guest, we collect identifiable information (such as name, email
              address, date of birth, gender, billing address, delivery address,
              pin code, mobile number etc.).
            </span>
          </p>
          <p>
            <span>
              We use this information to contact you about the services you are
              using on our website and services in which you have expressed
              interest. When you purchase a product or service from us, we
              request certain personally identifiable information from you on
              our order form, in order to be able to fulfil your requested
              service. You must provide contact information (such as name,
              email, and shipping address). We use this information for billing
              purposes and to fill your orders. If we have trouble processing an
              order, we will use this information to contact you.
            </span>
          </p>
          <p>
            <span>
              You have the option to provide demographic information (such as
              occupation, education and gender) to us; we encourage you to
              submit this information so we can provide you a more personalized
              experience on our website.
            </span>
          </p>
          <p>
            <span>
              If you choose to use our referral service to tell a friend about
              our website, we will ask you for your friend’s name and email
              address. We will automatically send your friend a one-time email
              inviting him or her to visit the website. Ezok does store this
              information and the information is used for the sole purpose of
              sending this one-time email and tracking the success of our
              referral program.
            </span>
          </p>
          <p>
            <span>Your friend may contact us at </span>
            <span>care@ezokshoes.com</span>
            <span>
              &nbsp;to request that we remove this information from our
              database. We are the sole owners of your information collected by
              us at different points on our Website. We will collect personally
              identifiable information about you only as part of a voluntary
              registration process, guest check out, on-line survey, or contest
              or any combination thereof.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              Our advertisers may collect anonymous traffic information from
              their own assigned cookies to your browser. The website contains
              links to other websites. We are not responsible for the privacy
              practices of such websites which we do not own, manage or control.
              We may at certain times make chat rooms, forums, instant messenger
              and message boards and other services available to you. Please
              understand that any information that is disclosed in these areas
              becomes public information. We have no control over its use and
              you should exercise caution when disclosing your personal
              information to anyone.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              If you use a bulletin board or chat room on this website, you
              should be aware that any personally identifiable information you
              submit there can be read, collected, or used by other users of
              these forums, and could be used to send you unsolicited messages.
              We are not responsible for the personally identifiable information
              you choose to submit in these forums.
            </span>
          </p>
          <p></p>
          <p>
            <b>
              <span>How is the information used?</span>
            </b>
          </p>
          <p>
            <span>We use your contact information to send you:</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>Password reminder and registration confirmation.</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>Special offers.</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>Changes in the service policies or terms of use.</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>
              Event based communications such as order information, renewal
              notices, invites, reminders etc.
            </span>
          </p>
          <p>
            <span>
              Note: We send users updates upon registration, and updates
              regarding your order status. We also send promotional emails to
              inform you about new arrivals, special offers, etc.
            </span>
          </p>
          <p></p>
          <p>
            <span>We use your personal information to:</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>
              Improve personalized features on our websites to enhance your
              shopping experience.
            </span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>
              To contact you for your account / profile related matters or any
              other reason as may be necessary.
            </span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>
              To provide the services requested by your example, deliver
              merchandise ordered by you.
            </span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>
              To preserve social history as governed by existing law or policy.
            </span>
          </p>
          <p></p>
          <p>
            <span>We use anonymous traffic information to:</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>
              Remember you in order to deliver better and more personalized
              service e.g. track your entries in our promotion schemes
            </span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>Recognize your usage privileges to our website.</span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>
              Administer and manage smooth functioning of our website by
              diagnosing problems.
            </span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>
              Track your session and activities so that we can understand better
              how people use our website.
            </span>
          </p>
          <p>
            <span>
              Who do we share your information with? We do not share or rent
              your information with third parties except our agents and alliance
              partners.
            </span>
          </p>
          <p></p>
          <p>
            <span>Agents</span>
          </p>
          <p></p>
          <p>
            <span>
              We use the services of our enabling partners such as outside
              shipping companies, resellers, business associates to fulfil
              orders, and credit / debit card processing companies to process
              the payment for goods and services ordered from Ezok website.
              These entities do not retain, store, share or use personally
              identifiable information for any other purposes.
            </span>
          </p>
          <p></p>
          <p></p>
          <p>
            <span>Alliance Partners</span>
          </p>
          <p></p>
          <p>
            <span>
              We will share your information with our alliance partners who work
              with us or on our behalf to help provide you the services. An
              alliance partner is a company or an individual who owns and
              manages (wholly or part of) online content on their websites
              powered by Ezok.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              We share email addresses with agents and Alliance partners. The
              agents and Alliance partners use the email address to confirm the
              deliveries, send notices and offer services related to the
              service.
            </span>
          </p>
          <p>
            <span>
              We do not rent, sell or share your personal information and we
              will not disclose any of your personally identifiable information
              to any other third parties unless:
            </span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>
              &#183;Required by special circumstances such as compliance with
              subpoenas, court orders, requests/order from legal authorities or
              law enforcement agencies requiring such disclosure;
            </span>
          </p>
          <p>
            <span>
              <span>&#183;</span>
            </span>
            <span>
              &#183;To help investigate, prevent or take action regarding
              unlawful and illegal activities, suspected fraud, potential threat
              to the safety or security of any person, violations of Ezok terms
              of use or to defend against legal claims;
            </span>
          </p>
          <p>
            <span>
              We have your permission to provide products or services you’ve
              requested
            </span>
          </p>
          <p></p>
          <p>
            <span>
              We reserve the right to disclose your personal identifiable and
              email address information as required by law and when we believe
              that disclosure is necessary to protect our rights and/or comply
              with a judicial proceeding, court order, or legal process served
              on our websites.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              We share your information with advertisers on an aggregate basis
              only
            </span>
          </p>
          <p></p>
          <p>
            <span>
              The security of your personal information and email address is
              important to us. When you enter sensitive information (such as
              credit / debit card number) on our acquiring bank’s system or
              order forms, they encrypt that information using secure socket
              layer technology (SSL). To learn more about SSL, follow this link
            </span>
            <span>
              <a href="http://www.verisign.com/">
                <u>
                  <span class="15">&nbsp;http://www.verisign.com/</span>
                </u>
              </a>
            </span>
          </p>
          <p></p>
          <p>
            <span>
              We follow generally accepted industry standards to protect the
              personal information and email address submitted to us, both
              during transmission and once we receive it. No method of
              transmission over the Internet, or method of electronic storage,
              is 100% secure, however. Therefore, while we strive to use
              commercially acceptable means to protect your personal information
              and email address, we cannot guarantee its absolute security.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              If you have any questions about security on our website, you can
              email us at:
            </span>
          </p>
          <p>
            <span>care@ezokshoes.com</span>
          </p>
          <p></p>
          <p>
            <span>
              What choices are available to you regarding collection, use and
              distribution of your information?
            </span>
          </p>
          <p></p>
          <p>
            <span>
              You can accept or decline the cookies. All websites that are
              customizable require that you accept cookies. You must also accept
              cookies to register as someone for access to some of our services.
              For information on how to set your browser to alert you to
              cookies, or to reject cookies, go to{" "}
            </span>
            <span>
              <a href="http://www.cookiecentral.com/">
                <u>
                  <span class="15">http://www.cookiecentral.com/</span>
                </u>
              </a>
            </span>
          </p>
          <p></p>
          <p>
            <span>Cookies</span>
          </p>
          <p></p>
          <p>
            <span>
              A cookie is a small text file that is stored on a user’s computer
              for record-keeping purposes. We use cookies on this website. We do
              link the information we store in cookies to any personally
              identifiable information you submit while on our website.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              We use both session ID cookies and persistent cookies. We use
              session cookies to make it easier and secure for you to navigate
              our website. A session ID cookie expires when you close your
              browser. A persistent cookie remains on your hard drive for an
              extended period of time. You can remove persistent cookies by
              following directions provided in your Internet browser's “help”
              file. Reference for cookies can be found at{" "}
            </span>
            <span>
              <a href="http://www.cookiecentral.com/">
                <u>
                  <span class="15">http://www.cookiecentral.com/</span>
                </u>
              </a>
            </span>
          </p>
          <p></p>
          <p>
            <span>
              We use session cookies to store the secure session and browsing
              preferences of the user. We set a persistent cookie to store your
              username and interests so you don’t have to enter it more than
              once. Persistent cookies also enable us to track and target the
              interests of our users to enhance the experience on our website.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              If you reject cookies, you may still use our website, but your
              ability to use some areas of our website will be limited. Cookies
              are used in the shopping bag to enable enhanced security and to
              ensure there is no URL based spamming.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              Unless you voluntarily identify yourself (through registration,
              for example), we will have no way of knowing who you are, even if
              we assign a cookie to your computer. The only personal information
              a cookie can contain is information you supply (an example of this
              is when transacting as a guest or adding items to a shopping bag).
              A cookie cannot read data off your hard drive.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              Some of our business partners (e.g., advertisers) set cookies
              while delivering banners on our website. We have no access to or
              control over these cookies. This privacy statement covers the use
              of cookies by Ezok only and does not cover the use of cookies by
              any advertisers. We do use cookies on the point of collection
              pages of email addresses but we do not use them in emails.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              Our web servers automatically collect limited information about
              your computer’s connection to the Internet, including your IP
              address, when you visit our website. (Your IP address is a number
              that lets computers attached to the Internet know where to send
              you data -- such as the web pages you view.) Your IP address does
              not identify you personally. We use this information to deliver
              our web pages to you upon request, to tailor our website to the
              interests of our customers, to measure traffic within our website
              and let advertisers know the geographic locations from where our
              visitors come.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              Ezok includes links to other websites. Such websites are governed
              by their respective privacy policies, which are beyond our
              control. Once you leave our servers (you can know where you are by
              checking the URL in the location bar on your browser), use of any
              information you provide is governed by the privacy policy of the
              operator of the website you are visiting. That policy may differ
              from ours. If you can’t find the privacy policy of any of these
              websites via a link from the website’s homepage, you should
              contact the website directly for more information.
            </span>
          </p>
          <p></p>
          <p>
            <span>Voluntary disclosure of information.</span>
          </p>
          <p></p>
          <p>
            <span>
              Supplying personally identifiable information is entirely
              voluntary. You are not required to register with us in order to
              use our websites. However, we offer some services only to visitors
              who do register:
            </span>
          </p>
          <p></p>
          <p>
            <span>
              You may change your interests at any time and may opt-in or
              opt-out of any marketing / promotional / newsletters mailings.
              Ezok reserves the right to send you certain service related
              communication, considered to be a part of your Ezok account
              without offering you the facility to opt-out. You may update your
              information and change your account settings at any time.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              We provide you with the opportunity to opt-out of having your
              email address used for certain purposes, when we ask for this
              information. For example, if you purchase a product / service but
              do not wish to receive any additional marketing material from us,
              you can indicate your preferences. If you no longer wish to
              receive our newsletter and promotional communications, you may
              opt-out of receiving them by selecting your preferences in “My
              Account”. If you still face an issue then you may or email us at
              Care@ezokshoes.com
            </span>
          </p>
          <p></p>
          <p>
            <span>
              If your personally identifiable information or email address
              change, or if you no longer desire our service, you may correct,
              update, delete or deactivate it by emailing our Customer Service
              at{" "}
            </span>
            <span>care@ezokshoes.com</span>
            <span>&nbsp;.</span>
          </p>
          <p></p>
          <p>
            <span>
              Upon request, we will remove / block your personally identifiable
              information from our database, thereby cancelling your
              registration. However, your information may remain stored in the
              archive on our servers even after the deletion or the termination
              of your account.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              If we plan to use your personally identifiable information for any
              commercial purposes, we will notify you at the time we collect
              that information and allow you to opt-out of having your
              information used for those purposes.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              What security procedures are in place to protect information from
              loss, misuse or alteration?
            </span>
          </p>
          <p></p>
          <p>
            <span>
              To protect your privacy and security, we will verify your identity
              before granting access or making changes to your personally
              identifying information. If you have registered your profile on
              Ezok, your ID and Password are required in order to access your
              profile information.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              To protect against the loss, misuse and alteration of the
              information under our control, we have in place appropriate
              physical, electronic and managerial procedures. For example, our
              servers are accessible only to authorized personnel and your
              information is shared with respective personnel on a need to know
              basis to complete the transaction and to provide the services
              requested by you.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              Although we will endeavour to safeguard the confidentiality of
              your personally identifiable information, transmissions made by
              means of the Internet cannot be made absolutely secure. By using
              this website, you agree that Ezok will have no liability for
              disclosure of your information due to errors in transmission or
              unauthorized acts of third parties.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              We follow generally accepted industry standards to protect the
              personal information and email addresses submitted to us, both
              during transmission and once we receive it. No method of
              transmission over the Internet, or method of electronic storage,
              is 100% secure, however. Therefore, while we strive to use
              commercially acceptable means to protect your personal information
              and email address, we cannot guarantee its absolute security.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              If you have any questions about security on our website, you can
              send email us at{" "}
            </span>
            <span>care@ezokshoes.com</span>
          </p>
          <p></p>
          <p>
            <span>
              All information gathered on Ezok website is securely stored within
              our controlled database. The database is stored on servers secured
              behind a firewall; access to the servers is password-protected and
              is strictly limited. However, as effective as our security
              measures are, no security system is impenetrable. We cannot
              guarantee the security of our database, nor can we guarantee that
              information you supply will not be intercepted while being
              transmitted to us over the Internet and, of course, any
              information you include in a posting to the discussion areas is
              available to anyone with internet access.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              However the internet is an ever evolving medium. We may revise our
              privacy policy from time to time to incorporate necessary changes.
              Of course, our use of any information we gather will always be
              consistent with the policy under which the information was
              collected, regardless of what the new policy may be.
            </span>
          </p>
          <p></p>
          <p>
            <span>
              We may use third-party advertising companies to serve ads when you
              visit our website. These companies may use information (not
              including your name, address, email address or telephone number)
              about your visits to this and other websites in order to provide
              advertisements about goods and services of interest to you.
            </span>
          </p>
          <p></p>
          <p>
            <span>How can you correct inaccuracies in the information?</span>
          </p>
          <p></p>
          <p>
            <span>
              To correct or update any information you have provided, our
              websites allow you to do it online.
            </span>
          </p>

          <p>
            <span>Policy updates</span>
          </p>

          <p>
            <span>
              We reserve the right to change or update this policy at any time
              by placing a prominent notice on our website. Such changes shall
              be effective immediately upon posting to this website.
            </span>
          </p>

          <p>
            <span>
              If we decide to change our privacy policy, we will post those
              changes to this privacy statement, the homepage, and other places
              we deem appropriate so that you are aware of what information we
              collect, how we use it, and under what circumstances, if any, we
              disclose it. We reserve the right to modify this privacy statement
              at any time, so please review it frequently. If we make material
              changes to this policy, we will notify you here, or by means of a
              notice on our homepage.
            </span>
          </p>

          <p>
            <span>
              If we decide to make changes in our email practices, we will post
              those changes to this privacy policy, the homepage, and other
              places we deem appropriate so that you are aware of what
              information we collect, how we use it, and under what
              circumstances, if any, we disclose it.
            </span>
          </p>
        </div>
      </Container>
    </>
  );
}

export default PrivacyPolicy;
