import React, { useState } from "react";
import {
  Row,
  Col,
  Form,
  FormControl,
  Button,
  Container,
} from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

function SearchComponent({ show, history, handleClose }) {
  const [keyword, setKeyword] = useState("");

  const submitHandler = (e) => {
    e.preventDefault();
    // if (keyword.trim()) {
    //   history.push("/search/" + keyword);
    // } else {
    //   history.push("/collections/all");
    // }
  };
  return (
    <div
      style={{
        transition: "all 5s ease",
        position: "absolute",
        top: "95px",
        background: "#CBCBCB",
        height: "100px",
        width: "100%",
        zIndex: "5000",
        display: show ? "block" : "none",
      }}
    >
      <Container style={{ height: "100%" }}>
        <Row
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Col>
            <Form className="d-flex w-100 ms-auto" onSubmit={submitHandler}>
              <Link
                type="submit"
                className="customInputButton rounded-0"
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  borderBottom: "1px solid",
                }}
              >
                <Search style={{ color: "#686868" }} />
              </Link>
              <FormControl
                type="text"
                name="q"
                value={keyword}
                onChange={(e) => setKeyword(e.target.value)}
                placeholder="Find Your Style"
                className="mr-sm-2 ml-sm-5 customInput"
                autoComplete="off"
                autoFocus
              ></FormControl>

              <Link
                className="btn rounded-0"
                style={{
                  color: "inherit",
                  textDecoration: "none",
                }}
                onClick={() => handleClose()}
              >
                x
              </Link>
            </Form>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default SearchComponent;
