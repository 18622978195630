import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import Header2 from "./components/Header2/Header2";
import Header3m from "./components/Header3m/Header3m";
import Footer3 from "./components/Footer3/Footer3";
import Footer2 from "./components/Footer2/Footer2";
// import Cart from "../src/components/Cart/Cart";
import ScrollToTop from "./components/ScrollToTop";
import ShopProvider from "./context/shopContext";

import Home from "./pages/Home";
import About from "./pages/About";
import EzokInside from "./pages/EzokInside";
import ProductsDetails from "./pages/ProductsDetails";
import BlogPage from "./pages/BlogPage";
import CartScreen from "../src/pages/CartScreen";
import WishListScreen from "../src/pages/WishListScreen";
import MyOrders from "../src/pages/MyOrders";
import Collections from "../src/pages/Collections";
import Collections2 from "../src/pages/Collections2";
import Collections3 from "../src/pages/Collections3";
import CBestSellers from "../src/pages/CBestSellers";
import CComfort from "../src/pages/CComfort";
import CDress from "../src/pages/CDress";
import CFlexi from "../src/pages/CFlexi";
import CMoccasin from "../src/pages/CMoccasin";
import CNewArrivals from "../src/pages/CNewArrivals";
import ContactScreen from "../src/pages/ContactScreen";
import SearchCollections from "../src/pages/SearchCollections";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ReturnPolicy from "./pages/ReturnPolicy";
import TandC from "./pages/TandC";
import FAQ from "./pages/FAQ";
import DeliveryPolicy from "./pages/DeliveryPolicy";
import Cart from "./components/Cart/Cart";
import { isMobile } from "react-device-detect";
import Login from "../src/pages/Login";
import Logout from "../src/pages/Logout";
import Userdashboard from "../src/pages/Userdashboard";

function App() {
  return (
    <ShopProvider>
      <Router>
        <ScrollToTop />
        {/* <Route component={isMobile ? Header3m : Header2} /> */}
        <Route component={Header2} />
        {/* <Header2 /> */}
        {/* <Cart /> */}
        <main>
          <Route
            path="/collections/bestseller"
            component={CBestSellers}
          ></Route>
          <Route path="/collections/comfort" component={CComfort}></Route>
          <Route path="/collections/dress" component={CDress}></Route>
          <Route path="/collections/moccasin" component={CMoccasin}></Route>
          <Route path="/collections/flexi" component={CFlexi}></Route>
          <Route
            path="/collections/newarrivals"
            component={CNewArrivals}
          ></Route>
          {/* <Route path="/collections/all" component={Collections}></Route> */}
          <Route path="/collections/all" component={Collections}></Route>
          <Route path="/collections2/all" component={Collections2}></Route>
          <Route
            path="/collections3/all/:type?"
            component={Collections3}
          ></Route>
          <Route path="/login" component={Login}></Route>
          <Route path="/logout" component={Logout}></Route>
          <Route path="/userdashboard" component={Userdashboard}></Route>
          <Route path="/cart" component={CartScreen}></Route>
          <Route path="/privacypolicy" component={PrivacyPolicy}></Route>
          <Route path="/returnpolicy" component={ReturnPolicy}></Route>
          <Route path="/termsandconditions" component={TandC}></Route>
          <Route path="/faq" component={FAQ}></Route>
          <Route path="/deliverypolicy" component={DeliveryPolicy}></Route>
          <Route path="/wishlist" component={WishListScreen}></Route>
          <Route path="/MyOrders" component={MyOrders}></Route>
          <Route path="/about" component={About}></Route>
          <Route path="/ezokinside" component={EzokInside}></Route>
          <Route path="/contactus" component={ContactScreen}></Route>
          {/* <Route path="/blog/:blogId/:id" component={BlogPage}></Route> */}
          <Route path="/blog/:id" component={BlogPage}></Route>
          <Route path="/product/:id" component={ProductsDetails}></Route>
          <Route path="/search/:keyword" component={SearchCollections}></Route>
          <Route path="/" component={Home} exact></Route>
        </main>
        <Footer3 />
        <Footer2 />
      </Router>
    </ShopProvider>
  );
}

export default App;
