import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import "../Product/Product.css";

function Product({ product, styles }) {
  return (
    <>
      <Card style={{ height: "auto" }}>
        <LinkContainer to={`/product/${product.id}`}>
          <Card.Img variant="top" src={product.images[0].src} style={styles} />
        </LinkContainer>
        <Card.Body style={{ background: "#e6e6e6" }} className="card-body">
          <Row>
            <Col>
              <Card.Text
                className="m-0 cardkatext"
                style={{ fontFamily: "sans-serif" }}
              >
                {product.title}
              </Card.Text>
              <Card.Text className="m-0" style={{ fontFamily: "sans-serif" }}>
                <strong>₹{product.variants[0].price}</strong>
              </Card.Text>
            </Col>
            <Col
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              className="mobcolAdjust"
            >
              <Link
                to={`/product/${product.id}`}
                className="btn btn-outline-dark buybtn mobfontA"
                style={{ borderRadius: "0", fontFamily: "sans-serif" }}
              >
                BUY NOW
              </Link>
            </Col>
          </Row>
        </Card.Body>
      </Card>
    </>
  );
}

Product.defaultProps = {
  styles: { display: "none" },
};

export default Product;
