const blogs = [
  {
    id: "1",
    category: "news",
    image: "/images/blogs/blog-n2.jpg",
    title: "Genuine Leather",
    description:
      "EZOK believes in a circular economy and supports the best practices in trade. Ezok wants to add value to the system and acknowledges the importance of renewable material, through effective and modern processing techniques. Reliably made leather is a respected substitute for oil-based products – PVC, plastics and Polyesters. Leather as a material lasts a very long time and it gets better with aging. The drive to sustainability leads with merchandises that can be used for longevity of use. Leathers are the most durable materials that are used for dressy as well comfort Shoes. It is breathable as well allows body temperature to be adjusted. It inherently absorbs and holds moisture away from the skin and corresponds to the users distinctive fit and  comfort.",
    date: "2021-05-31",
    subject:
      "EZOK believes in a circular economy and supports the best practices in trade.",
  },
  {
    id: "2",
    category: "news",
    image:
      "https://cdn.shopify.com/s/files/1/0570/6370/2695/articles/1.jpg?v=1627709280",
    title: "Handmade with perfection",
    description:
      "Our footwear crafting story commenced at the end of the last century, when we started manufacturing handmade women's shoes. We started with a humble fairness, but with much passion, inspiration and determination. This spirit has assisted us over the decades of unending hard work to earn loyal customers and to turn our processes into a state of the art unit. Today, our manufacturing plant produces world class footwear with a blend of traditional as well modern equipment and adhere to the highest technological standards in the industry. The shoes are handmade from luxurious leather, with splendid designs and unspoiled height and size that fits you perfectly.",
    date: "2021-05-31",
    subject:
      "Our footwear crafting story commenced at the end of the last century, when we started manufacturing handmade women's shoes.",
  },
  {
    id: "3",
    category: "news",
    image: "/images/blogs/blog-n.jpg",
    title: "Flexibility and Comfort",
    description:
      "The principal concerns with the footwear is poor fit. The ideal footwear for any age group of adults should fit well and have a low, broad heel, a thin and flexible sole, and some kind of lace, strap or velcro to ensure that the the shoe stays firmly attached to the foot. Ezok offers the best in class.",
    date: "2021-05-31",
    subject: "The principal concern with the footwear is poor fit.",
  },
  // {
  //   id: "4",
  //   category: "style",
  //   image: "b4.jpg",
  //   title: "We Love Helping People Feel Great About How They Look.",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //   date: "16/Aug/2021",
  //   subject:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  // },

  // {
  //   id: "5",
  //   category: "people",
  //   image: "b2.jpg",
  //   title: "asdasdsa.",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //   date: "16/Aug/2021",
  //   subject:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  // },
  // {
  //   id: "6",
  //   category: "people",
  //   image: "b1.jpg",
  //   title: "asdasdsa.",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //   date: "16/Aug/2021",
  //   subject:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  // },
  // {
  //   id: "7",
  //   category: "people",
  //   image: "b1.jpg",
  //   title: "asdasdsa.",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //   date: "16/Aug/2021",
  //   subject:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  // },
  // {
  //   id: "8",
  //   category: "people",
  //   image: "b1.jpg",
  //   title: "asdasdsa.",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //   date: "16/Aug/2021",
  //   subject:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  // },
  // {
  //   id: "9",
  //   category: "lifestyle",
  //   image: "b1.jpg",
  //   title: "asdasdsa.",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //   date: "16/Aug/2021",
  //   subject:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  // },
  // {
  //   id: "10",
  //   category: "before",
  //   image: "b3.jpg",
  //   title: "asdasdsa.",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //   date: "16/Aug/2021",
  //   subject:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  // },
  // {
  //   id: "11",
  //   category: "fashion",
  //   image: "b4.jpg",
  //   title: "asdasdsa.",
  //   description:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
  //   date: "16/Aug/2021",
  //   subject:
  //     "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam",
  // },
];

export default blogs;
