import React from "react";
import { Alert } from "react-bootstrap";

function Message({ children, varient }) {
  return <Alert variant={varient}>{children}</Alert>;
}

Message.defaultProps = {
  varient: "info",
};

export default Message;
