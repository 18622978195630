import React from "react";
import { ListGroup } from "react-bootstrap";
import { Link } from "react-router-dom";

function MyAccountNav() {
  return (
    <>
      <div style={{ backgroundColor: "#E2E3E5", padding: "30px 40px" }}>
        {/* <h2>My Account</h2> */}
        <ListGroup variant="flush">
          <Link to="/wishlist" style={{ textDecoration: "none" }}>
            <ListGroup.Item action variant="secondary border-0">
              My Wish List
            </ListGroup.Item>
          </Link>
          <Link to="/cart" style={{ textDecoration: "none" }}>
            <ListGroup.Item action variant="secondary border-0">
              My Cart
            </ListGroup.Item>
          </Link>
          {/* <a
           href="https://ezok-shoes.myshopify.com/account"
           target="_blank"
            style={{ textDecoration: "none" }}
          >
            <ListGroup.Item action variant="secondary border-0">
              My Orders
            </ListGroup.Item>
          </a> */}
          {/* <Link to="/refund" style={{ textDecoration: "none" }}>
            <ListGroup.Item action variant="secondary border-0">
              Refund Status
            </ListGroup.Item>
          </Link> */}
          {/* <div
            style={{
              background: "#ccc",
              height: "1px",
              width: "100%",
              margin: "10px 0",
            }}
          ></div>
          <ListGroup.Item action variant="secondary border-0">
            Edit Profile
          </ListGroup.Item>
          <ListGroup.Item action variant="secondary border-0">
            Sign Out
          </ListGroup.Item> */}
        </ListGroup>
      </div>
    </>
  );
}

export default MyAccountNav;
