import React, { Component } from "react";
import {
  Form,
  Button,
  FormControl,
  Container,
  Row,
  Col,
} from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Message from "../components/Message/Message";
export default class Login extends Component {
  constructor(props) {
    super(props);

    const token = localStorage.getItem("token");
    let isloggedin = true;
    if (token == null) {
      isloggedin = false;
    }
    this.state = {
      mobile: "",
      otp: "",
      _errMsg: "",
      isloggedin,
    };
    this.onChange = this.onChange.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }
  onChange(e) {
    this.setState({
      [e.target.name]: e.target.value,
    });
  }
  submitForm(e) {
    e.preventDefault();
    var UniqueID = Date.now();
    var ClientIDdata = Date.now();
    var ApiKeygen = (UniqueID + ClientIDdata).toString();
    const { mobile } = this.state;
    const { otp } = this.state;

    document.getElementById("otp").type = "hidden";
    // this.setState({_errMsg: 'Please enter mobile number'});
    if (mobile == "") {
      this.setState({ _errMsg: "Please enter mobile number" });
      return false;
    } else if (isNaN(mobile)) {
      this.setState({ _errMsg: "Please enter numbers only" });
      return false;
    } else if (mobile.length != 10) {
      this.setState({ _errMsg: "Please enter 10 digits numbers only" });
      return false;
    } else {
      document.getElementById("otp").type = "number";
      this.setState({ _errMsg: "" });
    }

    if (otp == "") {
      this.setState({ _errMsg: "Please enter otp number" });
      return false;
    } else if (isNaN(otp)) {
      this.setState({ _errMsg: "Please enter otp numbers only" });
      return false;
    } else if (otp.length != 4) {
      this.setState({ _errMsg: "Enter 4 digits otp" });
      return false;
    }

    var apidata = { phone: mobile };
    // call api
    axios.post("/loginsignup", apidata).then((res) => {
      console.log(res.data);
      var _resData = res.data;
      var _status = _resData.status;
      var _statusMessage = _resData.message;
      if (_status == 200) {
        this.setState({ _errMsg: "" });
        localStorage.setItem("ezokuserid", _resData.data);
        localStorage.setItem("token", ApiKeygen);
        this.setState({
          isloggedin: true,
        });
      } else {
        this.setState({ _errMsg: _statusMessage });
        // alert(_statusMessage)
      }
      // console.log(_status);
    });

    // return false;

    /*if(mobile === "8285431670")
    {
      localStorage.setItem("token", ApiKeygen);
      this.setState({
        isloggedin:true
      })
        // console.log(ApiKeygen);
    }*/
  }
  render() {
    if (this.state.isloggedin) {
      return <Redirect to="/cart" />;
    }
    return (
      <div>
        {this.state._errMsg && (
          <Message varient="danger">{this.state._errMsg}</Message>
        )}
        <Container>
          <Row className="p-3 contactp-0">
            <Col
              md={12}
              className="p-5 contactp-2 m-auto"
              style={{ width: "60%" }}
            >
              <Form
                onSubmit={this.submitForm}
                style={{ border: "2px solid", padding: "80px 50px" }}
                className="formContact"
                method="post"
                action="http://ezokshoes.com/email/index.php"
              >
                <Row className="mb-4">
                  <Col>
                    <h2
                      className="Contact-headings"
                      style={{ fontWeight: "900" }}
                    >
                      Login
                    </h2>
                    <div
                      style={{
                        height: "2px",
                        width: "100px",
                        background: "#C8A44C",
                        marginBottom: "10px",
                        marginTop: "10px",
                      }}
                    ></div>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="number"
                        maxLength="10"
                        name="mobile"
                        id="mobile"
                        placeHolder="Enter Mobile*"
                        className="contant-form"
                        onChange={this.onChange}
                        value={this.state.mobile}
                        required
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <Form.Control
                        type="hidden"
                        placeHolder="OTP"
                        maxLength="4"
                        name="otp"
                        id="otp"
                        value={this.state.otp}
                        onChange={this.onChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Button
                  variant="light"
                  type="submit"
                  className="rounded-0 custom-btn-buy"
                  name="submit"
                  id="submit"
                >
                  Submit
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
        {/* <div className="mainlogin">
          <h1>Login</h1>
          <span className="errmsg" style={{ color: "red" }}>
            {this.state._errMsg}
          </span>
          <form onSubmit={this.submitForm}>
            <input
              type="number"
              maxLength="10"
              name="mobile"
              id="mobile"
              value={this.state.mobile}
              onChange={this.onChange}
              placeHolder="Enter Mobile"
            />
            <br />
            <br />
            <input
              type="hidden"
              placeHolder="OTP"
              maxLength="4"
              name="otp"
              id="otp"
              value={this.state.otp}
              onChange={this.onChange}
            />
            <br />
            <input type="submit" />
          </form>
        </div> */}
      </div>
    );
  }
}
