import React, { Component } from "react";
import { Form, Button, FormControl } from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { Link, Redirect } from "react-router-dom";
export default class Logout extends Component {
  constructor(props) {
    super(props);
    const token = localStorage.removeItem("token");
    const user = localStorage.removeItem("ezokuserid");
    let isloggedin = false;
    if (token == null) {
      isloggedin = false;
    }
    this.state = {
      isloggedin,
    };
  }
  render() {
    if (this.state.isloggedin === false) {
      return <Redirect to="/" />;
    }
    return <div></div>;
  }
}
