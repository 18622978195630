import React, { useContext, useEffect } from "react";
import { Image, Container } from "react-bootstrap";
import { ShopContext } from "../context/shopContext";
import CarousalComponent from "../components/Carousal/CarousalComponent";
import CarousalComponent2 from "../components/Carousal2/CarousalComponent2";
import Jumbotrons from "../components/Jumbotrons/Jumbotrons";
import Categories from "../components/Categories/Categories";
import ProductParent from "../components/ProductParent/ProductParent";
import ShopByInstagram from "../components/ShopByInstagram/ShopByInstagram";
import EzokAdvantage from "../components/EzokAdvantage/EzokAdvantage";
import Blogs from "../components/Blogs/Blogs";
import Testimonails from "../components/Testimonails/Testimonails";
import { isMobile } from "react-device-detect";

function Home() {
  const { fetchAllProducts, products } = useContext(ShopContext);

  useEffect(() => {
    fetchAllProducts();
  }, []);

  return (
    <section
      style={
        isMobile
          ? { marginTop: "-80px" }
          : {
              marginTop: "-95px",
              background: `url('images/banners/pattern2.png')`,
              backgroundRepeat: "repeat",
            }
      }
    >
      {/* Carousal */}
      <CarousalComponent />
      <Container>
        <Jumbotrons />
        <Categories />
      </Container>
      <ProductParent products={products} />
      <Container>
        <Testimonails />
        <ShopByInstagram />
      </Container>
      <EzokAdvantage />
      <Container>
        <CarousalComponent2 />

        <Blogs />
      </Container>
    </section>
  );
}

export default Home;
