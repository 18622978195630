import React from "react";
import "../ShopByInstagram/ShopByInstagram.css";
import { Row, Col, Image } from "react-bootstrap";

function ShopByInstagram() {
  return (
    <section
      style={{ background: "#e6e6e6" }}
      className="instagramSection mt-4"
    >
      {/* <Row className="text-center p-5">
        <h4 style={{ fontFamily: "sans-serif" }}>Shop By Instagram</h4>
      </Row> */}
      <Row className="instaRow">
        <Col className="colshopI">
          <Image
            className="hoverE"
            src="images/instagram/11-1.jpg"
            fluid
          ></Image>
        </Col>
        <Col className="colshopI">
          <Image
            className="hoverE"
            src="images/instagram/11-2.jpg"
            fluid
          ></Image>
        </Col>
        <Col className="colshopI">
          <Image
            src="images/instagram/3.jpg"
            fluid
            style={{ border: "2px solid #808080" }}
          ></Image>
        </Col>
        <Col className="colshopI">
          <Image
            className="hoverE"
            src="images/instagram/11-3.jpg"
            fluid
          ></Image>
        </Col>
        <Col className="colshopI">
          <Image
            className="hoverE"
            src="images/instagram/11-4.jpg"
            fluid
          ></Image>
        </Col>
      </Row>
      <Row className="instaRow">
        <Col className="colshopI">
          <Image
            className="hoverE"
            src="images/instagram/11-5.jpg"
            fluid
          ></Image>
        </Col>
        <Col className="colshopI">
          <Image
            className="hoverE"
            src="images/instagram/11-6.jpg"
            fluid
          ></Image>
        </Col>
        <Col className="colshopI">
          <Image
            className="hoverE"
            src="images/instagram/11-7.jpg"
            fluid
          ></Image>
        </Col>
        <Col className="colshopI">
          <Image
            className="hoverE"
            src="images/instagram/11-8.jpg"
            fluid
          ></Image>
        </Col>
        <Col className="colshopI">
          <Image
            className="hoverE"
            src="images/instagram/11-9.jpg"
            fluid
          ></Image>
        </Col>
      </Row>
    </section>
  );
}

export default ShopByInstagram;
