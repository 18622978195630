import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import "../Categories/Categories.css";
import { Link } from "react-router-dom";

function Categories() {
  return (
    <>
      <Row className="mt-3">
        <div className="text-center py-4 nopad">
          <h5 className="Custom-headings-home">Categories</h5>
        </div>
        <Col xs={6} md={3} className="colCate">
          <div className="content">
            <div className="content-overlay"></div>
            <Link to="/collections3/all/dress?head=DRESS">
              <Image
                className="hoverup"
                src="images/categories/c62.jpg"
                fluid
              />
            </Link>
            <div className="content-details fadeIn-bottom">
              <h4 className="content-title">Dress Shoes</h4>
            </div>
          </div>
        </Col>
        <Col xs={6} md={3} className="colCate">
          <div className="content">
            <div className="content-overlay"></div>
            <Link to="/collections3/all/moccasin?head=MOCCASINS">
              <Image
                className="hoverup"
                src="images/categories/monk.jpg"
                fluid
              />
            </Link>
            <div className="content-details fadeIn-bottom">
              <h4 className="content-title">Moccasins</h4>
            </div>
          </div>
        </Col>
        <Col xs={6} md={3} className="colCate">
          <div className="content">
            <div className="content-overlay"></div>
            <Link to="/collections3/all/loafers?head=LOAFERS">
              <Image
                className="hoverup"
                src="images/categories/c72.jpg"
                fluid
              />
            </Link>
            <div className="content-details fadeIn-bottom">
              <h4 className="content-title">Loafers</h4>
            </div>
          </div>
        </Col>
        <Col xs={6} md={3} className="colCate">
          <div className="content">
            <div className="content-overlay"></div>
            <Link to="/collections3/all/sneakers?head=SNEAKERS">
              <Image
                className="hoverup"
                src="images/categories/sneakers.jpg"
                fluid
              />
            </Link>
            <div className="content-details fadeIn-bottom">
              <h4 className="content-title">Sneakers</h4>
            </div>
          </div>
        </Col>
        <Col xs={6} md={3} className="colCate">
          <div className="content">
            <div className="content-overlay"></div>
            <Link to="/collections3/all/monkstraps?head=MONKSTRAPS">
              <Image
                className="hoverup"
                src="images/categories/monk2.jpg"
                fluid
              />
            </Link>
            <div className="content-details fadeIn-bottom">
              <h4 className="content-title">Monk Straps</h4>
            </div>
          </div>
        </Col>
        <Col xs={6} md={3} className="colCate">
          <div className="content">
            <div className="content-overlay"></div>
            <Link to="/collections3/all/casuals?head=CASUALS">
              <Image
                className="hoverup"
                src="images/categories/casuals.jpg"
                fluid
              />
            </Link>
            <div className="content-details fadeIn-bottom">
              <h4 className="content-title">Casuals</h4>
            </div>
          </div>
        </Col>
        <Col xs={6} md={3} className="colCate">
          <div className="content">
            <div className="content-overlay"></div>
            <Link to="/collections3/all/formals?head=Formals">
              <Image
                className="hoverup"
                src="images/categories/slipers.jpg"
                fluid
              />
            </Link>
            <div className="content-details fadeIn-bottom">
              <h4 className="content-title">Formals</h4>
            </div>
          </div>
        </Col>
        <Col xs={6} md={3} className="colCate">
          <div className="content">
            <div className="content-overlay"></div>
            <Link to="/collections3/all/weddingspecials?head=WEDDING_SPECIALS">
              <Image
                className="hoverup"
                src="images/categories/wedding.jpg"
                fluid
              />
            </Link>
            <div className="content-details fadeIn-bottom">
              <h4 className="content-title">Wedding Specials</h4>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="mt-4"></Row>
    </>
  );
}

export default Categories;
