import React, { useState } from "react";
import {
  Col,
  Row,
  Form,
  FormControl,
  Container,
  Button,
} from "react-bootstrap";
import { ArrowRight } from "react-bootstrap-icons";
import "../FooterTop/FooterTop.css";
import Message from "../Message/Message";

function FooterTop() {
  const [isSubmit, setIsSubmit] = useState(false);
  return (
    <section style={{ background: "#e6e6e6" }}>
      {isSubmit && <Message varient="success">Subscribed Success ✅</Message>}
      <Container>
        <Row className="rowFTop">
          <Col xs={12} md={6}>
            {/* <Row className="childRowFTop">
              <Col>
                <p style={{ fontFamily: "sans-serif" }}>
                  ₹200 Coupon For First Shopping
                </p>
              </Col>
            </Row> */}
            <Row className="childRowFTop">
              <Col>
                <h2 style={{ color: "black" }}>SIGN UP FOR OUR NEWSLETTER</h2>
              </Col>
            </Row>
          </Col>
          <Col xs={12} md={6} className="m-auto">
            <Form
              onSubmit={() => setIsSubmit((prev) => !prev)}
              method="post"
              action="http://ezokshoes.com/email/index.php"
              className="d-flex"
              style={{ justifyContent: "center", width: "80%", margin: "auto" }}
            >
              <FormControl
                type="search"
                className="mr-2 input2"
                aria-label="Search"
                name="email"
                id="email"
                placeholder="Enter your email address"
              ></FormControl>
              <Button
                type="submit"
                variant="outline-dark"
                className="btn btn-outline-dark input2Button"
                name="submit1"
                id="submit1"
              >
                <ArrowRight />
              </Button>
            </Form>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default FooterTop;
