import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  FormControl,
  Row,
  Col,
  Container,
} from "react-bootstrap";
import { Search } from "react-bootstrap-icons";
import { Link, Redirect } from "react-router-dom";
import axios from "axios";
import Message from "../components/Message/Message";
import MyAccountNav2 from "../components/MyAccountNav2/MyAccountNav2";

const Userdashboard = ({ history }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState("");
  const [update, setUpdate] = useState(false);

  const token = localStorage.getItem("token");
  const userId = localStorage.getItem("ezokuserid");

  useEffect(() => {
    if (token === null) {
      setIsLoggedIn(false);
      history.push("/login");
    }

    const fetchUser = async () => {
      const { data } = await axios.get("/getuser/" + userId);

      setPhone(data.data.UserMobile);
      setEmail(data.data.email);
      setUserName(data.data.username);
    };

    fetchUser();

    if (isLoggedIn === false) {
      history.push("/login");
    }
  }, [history, token]);

  const handleSubmit = (e) => {
    const user = {
      username: userName,
      email,
    };

    const updateUser = async () => {
      const config = {
        headers: {
          "Content-Type": "application/json",
        },
      };

      const { data } = await axios.post("/userupdate/" + userId, user, config);

      setPhone(data.data.UserMobile);
      setEmail(data.data.email);
      setUserName(data.data.username);
      setUpdate(true);
    };

    updateUser();

    e.preventDefault();
  };

  return (
    <>
      <Container>
        <Row className="py-5 sideNav">
          <Col md={3}>
            <MyAccountNav2 />
          </Col>
          <Col md={1}></Col>
          <Col md={8}>
            {update && <Message varient="success">Profile Updated</Message>}
            <Row className="contactp-0">
              <Col
                md={12}
                className="contactp-2 m-auto"
                style={{ width: "80%" }}
              >
                <Form
                  style={{ border: "2px solid", padding: "80px 50px" }}
                  className="formContact"
                  onSubmit={(e) => handleSubmit(e)}
                >
                  <Row className="mb-4">
                    <Col>
                      <h2
                        className="Contact-headings"
                        style={{ fontWeight: "900" }}
                      >
                        Update Profile
                      </h2>
                      <div
                        style={{
                          height: "2px",
                          width: "100px",
                          background: "#C8A44C",
                          marginBottom: "10px",
                          marginTop: "10px",
                        }}
                      ></div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="text"
                          name="email"
                          id="email"
                          className="contant-form"
                          value={phone}
                          disabled
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="email"
                          name="email"
                          id="email"
                          placeHolder="Enter Email*"
                          className="contant-form"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="text"
                          name="username"
                          maxLength="18"
                          id="username"
                          placeHolder="Enter UserName*"
                          className="contant-form"
                          value={userName}
                          onChange={(e) => setUserName(e.target.value)}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="hidden"
                          placeHolder="OTP"
                          maxLength="4"
                          name="otp"
                          id="otp"
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    variant="light"
                    type="submit"
                    className="rounded-0 custom-btn-buy"
                  >
                    Update
                  </Button>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Userdashboard;
