import React, { useContext, useEffect, useState } from "react";
import {
  Nav,
  Navbar,
  Container,
  Image,
  Form,
  FormControl,
  Row,
  Col,
  Modal,
  Button,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { ShopContext } from "../../context/shopContext";
import "../Header2/Header2.css";
import {
  Bag,
  Heart,
  Person,
  Search,
  Cart,
  Phone,
  XLg,
  Telephone,
} from "react-bootstrap-icons";
import axios from "axios";
import { Link } from "react-router-dom";
import Message from "../Message/Message";
import SearchBox from "../SearchBox";
import { Route } from "react-router-dom";
import SearchComponent from "../SearchComponent/SearchComponent";
import { isMobile } from "react-device-detect";

function Header({ location, history }) {
  const [show, setShow] = useState(false);
  const [navBackground, setNavBackground] = useState("nav-bg");
  const [hoverLinks, setHoverLinks] = useState("hoverLinks");
  const [modalshow, setModalShow] = useState(false);
  const [mobile, setMobile] = useState("");
  const [otp, setOtp] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [isloggedin, setIsloggedin] = useState(true);
  const [showSearchModal, setShowSearchModal] = useState(false);
  const [keyword, setKeyword] = useState("");

  const handleModelClose = () => setModalShow(false);
  const handleModelShow = () => setModalShow(true);

  const handleSearchModelClose = () => setShowSearchModal(false);
  const handleSearchModelShow = () => setShowSearchModal(true);

  const navRef = React.useRef();
  navRef.current = navBackground;

  const token = localStorage.getItem("token");

  useEffect(() => {
    setIsloggedin(true);
    if (token == null) {
      setIsloggedin(false);
    }
  }, [token]);

  useEffect(() => {
    const handleScroll = () => {
      const show = window.scrollY > 310;
      if (show) {
        setNavBackground("nav-bg2");
        setHoverLinks("hoverLinks2");
      } else {
        setNavBackground("nav-bg");
        setHoverLinks("hoverLinks");
      }
    };

    document.addEventListener("scroll", handleScroll);
    // return () => {
    //   document.removeEventListner("scroll", handleScroll);
    // };
  }, []);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { openCart } = useContext(ShopContext);

  const submitForm = (e) => {
    e.preventDefault();
    var UniqueID = Date.now();
    var ClientIDdata = Date.now();
    var ApiKeygen = (UniqueID + ClientIDdata).toString();

    document.getElementById("otp").type = "hidden";
    if (mobile == "") {
      setErrMsg("Please enter mobile number");
      return false;
    } else if (isNaN(mobile)) {
      setErrMsg("Please enter numbers only");
      return false;
    } else if (mobile.length != 10) {
      setErrMsg("Please enter 10 digits numbers only");
      return false;
    } else {
      document.getElementById("otp").type = "number";
      setErrMsg("");
    }

    if (otp == "") {
      setErrMsg("Please enter otp number");
      return false;
    } else if (isNaN(otp)) {
      setErrMsg("Please enter otp numbers only");
      return false;
    } else if (otp.length != 4) {
      setErrMsg("Enter 4 digits otp");
      return false;
    }

    var apidata = { phone: mobile };
    // call api
    axios.post("/loginsignup", apidata).then((res) => {
      var _resData = res.data;
      var _status = _resData.status;
      var _statusMessage = _resData.message;
      if (_status == 200) {
        setErrMsg("");
        localStorage.setItem("ezokuserid", _resData.data);
        localStorage.setItem("token", ApiKeygen);
        setIsloggedin(true);
      } else {
        setErrMsg(_statusMessage);
      }
    });

    history.push("/userdashboard");
  };

  const handleRedirectDash = () => {
    history.push("/userdashboard");
  };

  const submitSearchHandler = (e) => {
    e.preventDefault();
    if (keyword.trim()) {
      history.push("/search/" + keyword);
    } else {
      history.push("/collections2/all");
    }
  };

  return (
    <header>
      <Navbar
        className={location.pathname === "/" ? navBackground : "nav-bg2"}
        variant={isMobile ? "light" : "dark"}
        expand="lg"
        collapseOnSelect
        fixed="top"
      >
        <Container className="header-container">
          <LinkContainer to="/">
            <Navbar.Brand>
              <Image
                src="/images/logo/logoF.png"
                alt="img"
                fluid
                className="navlogo"
              />
            </Navbar.Brand>
          </LinkContainer>
          {/* <LinkContainer to="/">
            <Navbar.Brand className="mdisplaynav">
              <Image
                src="/images/logo/logoF.png"
                alt="img"
                fluid
                className="navlogo"
              />
            </Navbar.Brand>
          </LinkContainer> */}
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <LinkContainer to="/collections2/all">
                <Nav.Link
                  className={
                    location.pathname === "/"
                      ? hoverLinks + " px-4 bebas navFont"
                      : "hoverLinks2 px-4 bebas navFont"
                  }
                >
                  SHOP
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/about">
                <Nav.Link
                  className={
                    location.pathname === "/"
                      ? hoverLinks + " px-5 bebas navFont"
                      : "hoverLinks2 px-5 bebas navFont"
                  }
                >
                  ABOUT US
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/ezokinside">
                <Nav.Link
                  className={
                    location.pathname === "/"
                      ? hoverLinks + " px-4 bebas navFont"
                      : "hoverLinks2 px-4 bebas navFont"
                  }
                >
                  BLOGS
                </Nav.Link>
              </LinkContainer>
            </Nav>
          </Navbar.Collapse>

          <Navbar.Collapse id="basic-navbar-nav" style={{ width: "10%" }}>
            <Nav className="ms-auto">
              {/* <div className="mdisplaynavnone">
                <Route
                  render={({ history }) => <SearchBox history={history} />}
                />
              </div> */}

              {/* <Nav.Link onClick={() => handleShow()}>
                <Search />
              </Nav.Link> */}

              {/* <LinkContainer to="/collections3/all/dress"> */}
              <Nav.Link
                onClick={handleSearchModelShow}
                className={
                  location.pathname === "/"
                    ? hoverLinks + " navFont2"
                    : "hoverLinks2"
                }
              >
                <Search />
              </Nav.Link>
              {/* </LinkContainer> */}
              <LinkContainer to="/contactus">
                <Nav.Link
                  className={
                    location.pathname === "/"
                      ? hoverLinks + " navFont2"
                      : "hoverLinks2 navFont2"
                  }
                >
                  <Telephone />
                </Nav.Link>
              </LinkContainer>

              {/* <LinkContainer to="/login"> */}
              <Nav.Link
                onClick={token === null ? handleModelShow : handleRedirectDash}
                className={
                  location.pathname === "/"
                    ? hoverLinks + " navFont2"
                    : "hoverLinks2 navFont2"
                }
              >
                <Person />
              </Nav.Link>
              {/* </LinkContainer> */}

              {/* <LinkContainer to="/wishlist">
                <Nav.Link>
                  <Search />
                </Nav.Link>
              </LinkContainer> */}
              <LinkContainer to="/wishlist">
                <Nav.Link
                  className={
                    location.pathname === "/"
                      ? hoverLinks + " navFont2"
                      : "hoverLinks2 navFont2"
                  }
                >
                  <Heart />
                </Nav.Link>
              </LinkContainer>
              <LinkContainer to="/cart">
                <Nav.Link
                  className={
                    location.pathname === "/"
                      ? hoverLinks + " navFont2"
                      : "hoverLinks2 navFont2"
                  }
                >
                  <Bag />
                </Nav.Link>
              </LinkContainer>
              {/* <div className="mdisplaynav">
                <Route
                  render={({ history }) => <SearchBox history={history} />}
                />
              </div> */}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
      {/* <Route
        render={({ history }) => (
          <SearchComponent
            show={show}
            handleClose={handleClose}
            history={history}
          />
        )}
      /> */}

      {/* Login Model */}

      <Modal show={modalshow} onHide={handleModelClose} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            <Image
              src="/images/logo/logoF.png"
              alt="img"
              fluid
              className="navlogo"
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {errMsg && <Message varient="danger">{errMsg}</Message>}
          <Container>
            <Row className="contactp-0">
              <Col md={12} className="contactp-3 m-auto">
                <Form
                  onSubmit={(e) => submitForm(e)}
                  style={{ padding: "20px 0px" }}
                  className="formContact"
                  method="post"
                  action="http://ezokshoes.com/email/index.php"
                >
                  <Row className="mb-4">
                    <Col>
                      <h2
                        className="Contact-headings"
                        style={{ fontWeight: "900" }}
                      >
                        Login
                      </h2>
                      <div
                        style={{
                          height: "2px",
                          width: "100px",
                          background: "#C8A44C",
                          marginBottom: "10px",
                          marginTop: "10px",
                        }}
                      ></div>
                    </Col>
                  </Row>

                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="number"
                          maxLength="10"
                          name="mobile"
                          id="mobile"
                          placeHolder="Enter Mobile*"
                          className="contant-form"
                          onChange={(e) => setMobile(e.target.value)}
                          value={mobile}
                          required
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Control
                          type="hidden"
                          placeHolder="OTP"
                          maxLength="4"
                          name="otp"
                          id="otp"
                          value={otp}
                          onChange={(e) => setOtp(e.target.value)}
                        />
                      </Form.Group>
                    </Col>
                  </Row>

                  <Button
                    variant="light"
                    type="submit"
                    className="rounded-0 custom-btn-buy"
                    name="submit"
                    id="submit"
                  >
                    Submit
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleModelClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Login Model */}
      {/* Search Model */}

      <Modal
        show={showSearchModal}
        onHide={handleSearchModelClose}
        keyboard={false}
        dialogClassName="modal-90w"
      >
        <Modal.Body style={{ background: "#E8E8E8" }}>
          <Form onSubmit={submitSearchHandler}>
            <Row>
              <Col
                xs={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button variant="light-outline" type="submit">
                  <Search />
                </Button>
              </Col>
              <Col
                xs={10}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <Form.Group
                  className="mb-3"
                  controlId="formBasicEmail"
                  style={{ width: "100%", marginTop: "1vw" }}
                >
                  <Form.Control
                    type="text"
                    name="search"
                    id="search"
                    placeHolder="Search..."
                    className="contant-form searchInput"
                    style={{ background: "#E8E8E8", border: "none" }}
                    value={keyword}
                    onChange={(e) => setKeyword(e.target.value)}
                  />
                </Form.Group>
              </Col>
              <Col
                xs={1}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="light-outline"
                  onClick={handleSearchModelClose}
                >
                  <XLg />
                </Button>
              </Col>
            </Row>
          </Form>
        </Modal.Body>
      </Modal>

      {/* Search Model */}
    </header>
  );
}

export default Header;
