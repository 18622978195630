import React, { useContext } from "react";
import { Button } from "react-bootstrap";
import { ShopContext } from "../../context/shopContext";

function QuantityComponent({ variantId, itemQty, itemId }) {
  const { addItemToCheckout, updateItemToCheckout, checkout } =
    useContext(ShopContext);

  return (
    <>
      <Button
        variant="outline-dark"
        className="py-0 px-2"
        onClick={() => {
          addItemToCheckout(variantId, 1);
        }}
      >
        +
      </Button>{" "}
      {itemQty}{" "}
      <Button
        variant="outline-dark"
        className="py-0 px-2"
        onClick={() => updateItemToCheckout(itemId, itemQty - 1)}
      >
        -
      </Button>
    </>
  );
}

export default QuantityComponent;
